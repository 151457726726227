import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  Curiouser25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Lift Off 25"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Alice in Wonderland</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Complete Event Planner, Confectioner or Barista. Complete Apothecary, Mixologist or the advanced version of either.</div>
                    <div className="badgePageItemEvidence"> 
                       
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Be Curious</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Complete one blue badge and one brown bagde</div>
                    <div className="badgePageItemEvidence"> 
                       
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Curious</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Collect 12 curious facts and present them in an interesting way.</div>
                    <div className="badgePageItemEvidence"> 
                       
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Curiouser25