import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';


const  January25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Butterfly Effect"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Litter Picking</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Spend at least 30 mins litter picking a week</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I always do at least 3 hours of litter picking a week so will be keeping that up this month.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Self-Care</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Spend at least 30 mins a week of self-care</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I'm not good at self care, so this is a useful reminder to have a pamper session occassionally!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle duskyblue">Digital Detox</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Have an email or social media cull</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have started doing this already! I've been cleaning up my emails and unsubscribing to a lot of things. My social media is on a constant cull as I change things up often.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle grey">Declutter</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Pick an area of the home, 15 mins a week to clear out.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I don't have one area of my home that needs clearing out, but I do want to clear out my wardrobe and make changes in the kitchen, so I will be working on that instead.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle duskyblue">Food</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Eat an extra portion of fruit or veg each day</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Included in meal planning!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle duskyblue">Help Out</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Help someone else for at least 30mins each week</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I do this every week all the time, so I will be continuing this month.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle grey">Recycle</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Recycle or rehome 10 items of clothing that you haven't worn for at least 2 years</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Clothing added to recycling bags as a result of the clear up above!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Tasks</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Choose a task you usually procrastinate with and just do it</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Well honestly, this has to be washing up, so duly done on time!!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Cause</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Take a small step to support a cause which is important to you</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have been working on Advanced Animal Lover and as part of that I adopted 8 lobster babies from the hatchery in Cornwall. Lobster have great social and economic value and is the most valuable marine species caught in the UK. They are subject to huge pressure from fishing and vulnerable to catastrophic stock collapse. Survival rate can be improved by around 1000 times, using modern technology. Supporting marine life is important, but you don't hear as much about it as land-based creatures and habitats, so I choose to support marine-based organisations.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default January25