import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Blondies1 from '../images/cupjan25-blondies1.jpg';
import Blondies2 from '../images/cupjan25-blondies2.jpg';
import Blondies3 from '../images/cupjan25-blondies3.jpg';
import Blondies4 from '../images/cupjan25-blondies4.jpg';
import Blondies5 from '../images/cupjan25-blondies5.jpg';
import Blondies6 from '../images/cupjan25-blondies6.jpg';
import Blondies7 from '../images/cupjan25-blondies7.jpg';
import Sticker from '../images/cupjan25-sticker.jpg';
import Passport1 from '../images/cupjan25-passport1.jpg';
import Passport2 from '../images/cupjan25-passport2.jpg';
import Passport3 from '../images/cupjan25-passport3.jpg';
import Passport4 from '../images/cupjan25-passport4.jpg';
import Passport5 from '../images/cupjan25-passport5.jpg';
import Passport6 from '../images/cupjan25-passport6.jpg';
import Passport7 from '../images/cupjan25-passport7.jpg';
import Passport8 from '../images/cupjan25-passport8.jpg';
import Passport9 from '../images/cupjan25-passport9.jpg';
import Passport10 from '../images/cupjan25-passport10.jpg';




const CupJan25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup January 2025 Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Blondies</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I made white chocolate and macadamia blondies for this clause</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Blondies1} alt="Butter and sugar melting in a pan" /></div>
                            <div className="galleryItem"><img src={Blondies2} alt="White chocolate being weight on an electronic scale" /></div>
                            <div className="galleryItem"><img src={Blondies3} alt="Melted butter and sugar in a pan" /></div>
                            <div className="galleryItem"><img src={Blondies4} alt="White chocolate and macadamia nuts chopped ready to go into blondie mix" /></div>
                            <div className="galleryItem"><img src={Blondies5} alt="Blondie mix in an over tray ready to go into the oven" /></div>
                            <div className="galleryItem"><img src={Blondies6} alt="Baked blondie mix in an oven tray" /></div>
                            <div className="galleryItem"><img src={Blondies7} alt="Baked blondies sliced into portion and stacked on a plate" /></div>
                        </div>     
                        <p>Although I overcooked them slightly, they were crunchy on the outside and chewy on the inside, just how I like them!</p>
                        <br/>                    
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Sticker</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here's the sticker that I designed to represent me for this year's challenges</p>
                        <img src={Sticker} width="300" className="imgWidthAuto" alt="A small round design showing a large rainbow shaded star with the letter A in the centre and small stars and a crown in between the points." />              
                        <br/>
                        <p>2/1/25: Waiting for the stickers to arrive from the printers and for confirmation of someone to swap with.</p>
                        <p>4/1/25: Now confirmed a rebel to swap with, excited to send and receive!</p>
                        <p>Stickers exchanged, both now in passport.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Passport</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I decided to create a passport that looked similar to the real UK passport, by designing a rebel themed crest for the centre!</p>
                        <p>Instead of the lion, unicorn and crown, I used the letters ABC to represent the year's rebel cup theme.</p>
                        <p>The words on the seal say "Insigne Liber Opus" which translates to "Badge Book Work".</p>
                        <p>The words on the scrool say "pas une secte" which translates to "not a cult" - a rebel inside joke!</p>
                        <p>Instead of the shield I have used the circles to represent the badges, each containing a letter that represents a rebel patrol.</p>
                        <br/>
                        <p>I can't find the video I watched, but the technique is the same as this one: https://www.youtube.com/watch?v=KKojHFky150</p>
                        <p>The book was made using paper, card and glue.</p>
                        <p>I designed the cover on Publisher and then traced it onto the book cover.</p>
                        <p>I used white gel pen to make the outlines clearer, then used a very fine paint brush to fill in the design with gold calligraphy ink.</p>
                        <p>I think it turned out ok! Just got to start on the inside.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Passport1} alt="A mocked up design of a crest in Publisher" /></div>
                            <div className="galleryItem"><img src={Passport2} alt="Paper on a guillotine being sliced into A6 sheets" /></div>
                            <div className="galleryItem"><img src={Passport3} alt="Coloured and white paper, cut to size to make an A6 book" /></div>
                            <div className="galleryItem"><img src={Passport4} alt="Pencil marking on a piece of black card to make a book cover" /></div>
                            <div className="galleryItem"><img src={Passport5} alt="Colourful paper cut to A5 size" /></div>
                            <div className="galleryItem"><img src={Passport6} alt="Black card and colourful paper cut and ready to make into a book" /></div>
                            <div className="galleryItem"><img src={Passport7} alt="Tracing a crest design" /></div>
                            <div className="galleryItem"><img src={Passport8} alt="Cover of a book, black with traced design outlined in white ink" /></div>
                            <div className="galleryItem"><img src={Passport9} alt="Gold calligraphy ink and paint brush being used to colour a design on black card" /></div>
                            <div className="galleryItem"><img src={Passport10} alt="Black passport cover with gold painted design" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupJan25