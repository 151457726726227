import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  April25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Back to the Future"} />
            <div className="badgePageItem">
               <div className="badgePageItemTitle orange">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light">Play an old arcade game</div>
                    <div className="badgePageItemEvidence"> 
                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
               <div className="badgePageItemTitle orange">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light">Watch an episode of a show you watched as a child. Compare it to a modern equivalent.</div>
                    <div className="badgePageItemEvidence"> 
                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
               <div className="badgePageItemTitle orange">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light">Revisit an old childhood collection. Do you still have it? How much do the items retail for these days?</div>
                    <div className="badgePageItemEvidence"> 
                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
               <div className="badgePageItemTitle orange">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light">Watch an old episode of Tomorrow's World. Which items of technology ended up changing our world?</div>
                    <div className="badgePageItemEvidence"> 
                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
               <div className="badgePageItemTitle orange">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light">Create some Spirograph Art</div>
                    <div className="badgePageItemEvidence"> 
                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
               <div className="badgePageItemTitle orange">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light">Learn about a current space exploration mission</div>
                    <div className="badgePageItemEvidence"> 
                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
               <div className="badgePageItemTitle orange">Clause 7</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light">Keep a Tamagotchi alive for at least a week</div>
                    <div className="badgePageItemEvidence"> 
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default April25