import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import CerneAbbas from '../images/Mythology-CerneAbbasGiant.jpg';
import GreyMare from '../images/Mythology-GreyMare.jpg';
import HellStone from '../images/Mythology-HellStone.jpg';
import Barrows from '../images/Mythology-SingingBarrows.jpg';
import WhiteHare from '../images/Mythology-WhiteHare.jpg';
import DrawingBase from '../images/Mythology-DrawingBase.png';
import Drawing from '../images/Mythology-Drawing.jpg';

const Mythology = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Mythology"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Myths, Legends and Fables</div>
                <div className="badgePageItemInfo hidden">Understand the difference between a myth, a legend and a fable. Spend at least three months reading different myths
                from around the world. Cover all the populated continents, your own area and a range of different ancient civilisations.</div>
                <div className="badgePageItemContent">
                    <p><strong>Myths</strong></p>
                    <p>Myths are traditional stories, they explain natural phenomena, the origins of the world or the beliefs of a culture and usually feature gods, supernatural beings or heroes. They have deep roots in the spiritual or religious practices of a society.</p>
                    <p>An example of a myth is the Greek myth of Prometheus, who stole fire from the gods and gave it to humanity.</p>
                    <br/>
                    <p><strong>Legends</strong></p>
                    <p>These are stories that are based on real historical events or people but are often exaggerated or include fictional elements. Their purpose is to inspire or to teach moral lessons and may change over time. They differ from myths in that they don't primarily focus on explaning natural phenomena.</p>
                    <p>An example of a legend is that of King Arthur and the Knights of the Round Table.</p>
                    <br/>
                    <p><strong>Fables</strong></p>
                    <p>Fables are short stories and usually feature animals, plants or inanimate object that have human-like qualities (anthropomorphism). Their purpose is to teach a moral or a lessen and are more straightforward and less tied to history or religion.</p>
                    <p>An example of a fable in Aesop's fable "The Tortoise and the Hare" - this teaches us that slow and steady wins the race.</p>
                    <br/><br/>
                    <p><strong>Myths from around the world</strong></p>
                    <ul className="list">
                        <li><strong>Myth of Anansi the Spider - Africa (Ashanti people, Ghana)</strong> : Anansi is a cunning and clever trickster figure who often uses his wits to overcome challenges. His tales are humorous and full of life lessons.</li>
                    </ul>
                    <br/>
                    <ul className="list">
                        <li><strong>The Tamayana - India: </strong> This Hindu myth is a story of duty, love and heroism. It follows Prince Rama on his journey to rescue his wife, Sita, from the demon king Ravana.</li>
                    </ul>                
                    <ul className="list">
                        <li><strong>The myth of Orpheus and Eurydice - Europe (Ancient Greece) : A myth about love, loss and the limits of human will. Orpheus, a gifted musician, ventures into the underworld to bring back his beloved wife, Eurydice</strong></li>
                    </ul>
                    <ul className="list">
                        <li><strong>The Legend of Sedna - North America (Inuit mythology) : </strong>Sedna is a sea goddess who controls marine creatures. Her myth explains the origins of sea life and portrays her as a powerful and complex figure.</li>
                    </ul>
                    <ul className="list">
                        <li><strong>The Tale of Pachamama - South America (Inca mythology, Peru) : Pachamama, the Earth goddess, is revered as a provider of fertility and abundance. Her stories emphasise the harmony between humans and nature.</strong></li>
                    </ul>
                    <ul className="list">
                        <li><strong>The Dreamtime Creation Myths - Oceania (Australian Aboriginal cultures) : These myths describe the ancestral beings who shaped the land, its creatures, and its laws. Each Aboriginal group has unique Dreamtime stories deeply tied to their landscapes.</strong></li>
                    </ul>
                    <br/><br/>
                    <p><strong>African Civilisations</strong></p>
                    <ul className="list">
                        <li><strong>Yoruba (Nigeria): </strong>The myth of Olodumare and the Orishas explains the creation of the world and humanity by the supreme god Olodumare with the help of divine entities called Orishas.</li>
                        <li><strong>Zulu (South Africa): </strong>: The story of Unkulunkulu tells of the first man who came from a reed and brought humanity into existence.</li>
                    </ul>
                    <br/>
                    <p><strong>Asian Civilisations</strong></p>
                    <ul className="list">
                        <li><strong>Chinese: </strong>The myth of Pan Gu describes the creation of the universe, where Pan Gu separates chaos into heaven and earth.</li>
                        <li><strong>Japanese: </strong>The story of Izanagi and Izanami explains the creation of the islands of Japan and its deities.</li>
                        <li><strong>Hindu (India): </strong>Myths like that of Vishnu's Dashavatara (Ten Avatars) depict cosmic cycles and the preservation of the universe.</li>
                    </ul>
                    <br/>
                    <p><strong>European Civilisations</strong></p>
                    <ul className="list">
                        <li><strong>Greek: </strong>The Titanomachy, or the battle between the Titans and Olympians, marks the rise of Zeus and the establishment of the gods of Mount Olympus.</li>
                        <li><strong>Norse (Scandinavia): </strong>The myth of Ragnarök predicts the end of the world and the rebirth of a new cycle.</li>
                    </ul>
                    <br/>
                    <p><strong>Indigenous Civilisations of the Americas</strong></p>
                    <ul className="list">
                        <li><strong>Aztec (Mesoamerica): </strong>The myth of the Five Suns explains the cycles of creation and destruction of the world by gods.</li>
                        <li><strong>Haudenosaunee (Iroquois, North America): </strong>: The story of Sky Woman tells of the creation of the earth on the back of a giant turtle.</li>
                    </ul>
                    <br/>
                    <p><strong>South American Civilisations</strong></p>
                    <ul className="list">
                        <li><strong>Inca (Peru): </strong>The myth of Viracocha recounts how this god created the sun, moon, and stars, as well as the first humans.</li>
                        <li><strong>Mapuche (Chile): </strong>The story of the Trentren and Caicai spirits explains the origin of mountains and floods.</li>
                    </ul>
                    <br/>
                    <p><strong>Oceanian Civilisations</strong></p>
                    <ul className="list">
                        <li><strong>Polynesian (Hawaiian): </strong>The myth of Pele, the goddess of volcanoes, tells of her fiery temper and her role in shaping the Hawaiian Islands.</li>
                        <li><strong>Maori (New Zealand): </strong>The story of Māui fishing up the North Island of New Zealand highlights the creativity and bravery of the culture hero Māui.</li>
                    </ul>
                    <br/>
                    <p><strong>Middle Eastern Civilisations</strong></p>
                    <ul className="list">
                        <li><strong>Sumerian (Mesopotamia): </strong>The Epic of Gilgamesh, one of the earliest known works of literature, tells of Gilgamesh's quest for immortality and the great flood.</li>
                        <li><strong>Hebrew: </strong>The creation story in the Book of Genesis narrates the origins of the world, humanity, and morality.</li>
                    </ul>
                    <br/><br/>
                    <p><strong>Local Mythology</strong></p>
                    <p>My home area of Dorset has several myths and legends, here are a few:</p>
                    <br/>
                    <ul className="list">
                        <li><strong>The Cerne Abbas Giant: </strong>This is an iconic chalk figure, carved into a hillside near the Village of Cerne Abbas. There is a lot of mystery surrounding it, with some people believing it represents an ancient fertility god and others believing it may depict Hercules. The origins of the giant are uncertain.</li>
                        <li><strong>The Singing Barrows: </strong>There are ancient burial mounds along the ridgewat between Weymouth and Dorchester that are knwon as the "Singing Barrows". It is said that they emit strange, melodic sounds at certain times.</li>
                        <li><strong>The Grey Mare and Her Colts: </strong>There is a group of standing stones near Abbotsbury which, according to folklore were once a grey mare and her foals that were turned to stone as punishment for working on the Sabbath.</li>
                        <li><strong>The White Hare: </strong>The South Dorset Ridgeway is said to be haunted by a ghostly white hare, which is believed to be the spirit of a wronged woman. Encountering the spirit is believed to be a bad omen.</li>
                        <li><strong>The Hell Stone: </strong>This is an ancient dolmen near Portesham. It is linked to tales of the supernatural, with some saying that it marks the entrance to the underworld and others believing that it has protective powers.</li>
                    </ul>
                    <br/>
                    <div className="gallery">
                        <div className="galleryItem"><img src={CerneAbbas} alt="The Cerne Abbas Giant, arial view"/></div>
                        <div className="galleryItem"><img src={Barrows} alt="The Singing Barrows in Dorset"/></div>
                        <div className="galleryItem"><img src={GreyMare} alt="The Grey Mare and Her Colts, Dorset"/></div>
                        <div className="galleryItem"><img src={WhiteHare} alt="Illustration of The White Hare myth"/></div>
                        <div className="galleryItem"><img src={HellStone} alt="The Hell Stone, Dorset"/></div>
                    </div>
                    <p>Images taken from Google, credits to all original owners.</p>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Cultures</div>
                <div className="badgePageItemInfo hidden">Choose at least three different cultures or time periods, and draw parallels between the stories. Are there
                common elements or themes which appear? Write your own myth, including some of these elements.</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I looked at stories from Ancient Greece, Medieval England and Polynesia.</p>
                    <br/>
                    <ul className="list">
                        <li><strong>The Myth of Prometheus (Ancient Greece, ~8th century BCE): </strong>Prometheus defies Zeus by stealing fire from the gods to give to humanity, enabling progress and civilisation. As punishment, he is bound to a rock where an eagle eats his liver daily, which regenerates each night.</li>
                        <li><strong>The Legend of Robin Hood (Medieval England, ~12th century): </strong>Robin Hood, an outlaw, rebels against the corrupt ruling class, robbing from the rich to aid the poor. He symbolizes resistance against injustice and the redistribution of power and wealth.</li>
                        <li><strong>The Myth of Maui and the Sun (Polynesia, ~1st millennium CE): </strong>: Māui, a demigod, captures the Sun to slow its passage across the sky, giving humanity more daylight to work and thrive. His actions, though bold and risky, improve life for his people.</li>
                    </ul>
                    <br/>
                    <p>The parallels that can be drawn between these tales are: </p>
                    <ul className="list">
                        <li><strong>Defiance of authority: </strong>Prometheus defies Zeus, Robin Hood challenges feudal lords, and Māui outsmarts the Sun. Each rebels against a higher power or system to achieve their goals.</li>
                        <li><strong>Altruism and Sacrifice: </strong>All three act for the benefit of others. Prometheus suffers eternal torment, Robin Hood risks his life for the oppressed, and Māui takes great personal risks to help his community.</li>
                        <li><strong>Catalysts for Progress: </strong>Acts of defiance as being transformative. Prometheus's fire symbolises technological advancement, Robin Hood's redistribution supports social justice, and Māui's daylight increases productivity</li>
                    </ul>
                    <br/>
                    <br/>
                    <p>The common elements broken down further:</p>
                    <ol className="list">
                        <li><strong>The Hero's Defiance: All three heroes rebel against a powerful force or established order to achieve their goals.</strong>
                            <ul>
                                <li>Prometheus defies Zeus by stealing fire for humanity.</li>
                                <li>Robin Hood challenges corrupt authority by redistributing wealth.</li>
                                <li>Māui captures and controls the Sun to help his people.</li>
                            </ul>
                        </li>
                        <li><strong>The Greater Good: </strong>Each hero's actions serve others rather than themselves.
                            <ul>
                                <li>Prometheus brings fire, a symbol of knowledge and progress, to humanity despite knowing the consequences.</li>
                                <li>Robin Hood risks his life to help the poor and stand against injustice.</li>
                                <li>Māui's act of slowing the Sun benefits his entire community by providing more daylight. </li>
                            </ul>
                        </li>
                        <li><strong>Sacrifice and Risk: </strong>Their willingness to sacrifice underscores their heroism.
                            <ul>
                                <li>Prometheus endures eternal punishment for his actions.</li>
                                <li>Robin Hood constantly faces danger and the threat of capture.</li>
                                <li>Māui risks his life in a daring and dangerous feat to tame the Sun.</li>
                            </ul>
                        </li>
                        <li><strong>Transformation and Progress: </strong>These myths emphasize change and progress as results of courage and defiance.
                            <ul>
                                <li>Fire transforms civilization in Prometheus's story.</li>
                                <li>Robin Hood's legend symbolizes the hope for a more just society.</li>
                                <li>Māui's success changes the natural order, improving life for his people.</li>
                            </ul>
                        </li>
                        <li><strong>Moral or Inspirational Themes: </strong>
                            <li>They all inspire admiration for individuals who dare to challenge the status quo for the benefit of others.</li>
                            <li>These stories highlight the enduring appeal of figures who fight for justice, innovation, or equality.</li>
                        </li>
                    </ol>
                    <br/><br/>
                    <p><strong>Writing a Myth</strong></p>
                    <p>I'm choosing not to share this here, so you'll have to trust that I did it!</p>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Myth</div>
                <div className="badgePageItemInfo hidden">Write and illustrate your version of a favourite myth or retell a myth verbally to someone who has never heard it before</div>
                <div className="badgePageItemContent">
                    <p>I have been retelling the myth of Orpheus and Euridice to anyone who will listen!</p>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Cryptozoology</div>
                <div className="badgePageItemInfo hidden">Learn about Cryptozoology. What is the difference between a cryptid and a mythical creature? Research the origins and
                stories surrounding at least five different cryptids and mythical beasts. Are there parallels between certain cryptids?</div>
                <div className="badgePageItemContent">
                    <p><strong>Crytozoology</strong></p>
                    <p>Cryptozoology is the study of and search for creatures - called Cryptids - who have not yet been proven to exist by mainstream science. Where zoology focuses on animals that are proven to exist, cryptozoology focuses on animals that are reported in folklore, myths and anecdotal evidence.</p>
                    <br/>
                    <p><strong>Key features</strong></p>
                    <ol className="list">
                        <li><strong>Cryptids: </strong>Examples include Bigfoot, the Loch Ness Monster, the Chupacabra, and the Mokele-Mbembe.</li>
                        <li><strong>Approach: </strong>Often blends science with speculations, folklore and anecdotal reports.</li>
                        <li><strong>Debate: </strong>Considered a pseudoscience by most scientists as there is a lack of empirical evidence that supports the existence of cryptids.</li>
                    </ol>
                    <br/><br/>
                    <p><strong>Cryptids vs Mythical Creatures</strong></p>
                    <p>The differences between cryptids and mythical creatures is mostly based in their origins and the way that they are perceived.</p>
                    <br/>
                    <p><strong>Cryptid</strong></p>
                    <ul className="list">
                        <li><strong>Definition: </strong>Cryptids are creatures that are believed by some to exist but lack scientific evidence to confirm their existence.</li>
                        <li><strong>Examples: </strong>Bigfoot, the Loch Ness Monster, and the Jersey Devil.</li>
                        <li><strong>Origins: </strong>Stories typically come from folklore, eyewitness accounts, or unverified evidence like blurry photos or footprints.</li>
                        <li><strong>Possibility of Existence: </strong>: Cryptids are often treated as potentially real animals that simply haven't been discovered or classified by science.</li>
                    </ul>
                    <br/>
                    <p><strong>Mythical Creature</strong></p>
                    <ul className="list">
                        <li><strong>Definition: </strong>Mythical creatures are beings from mythology, folklore, or imagination that are not considered real by science.</li>
                        <li><strong>Examples: </strong>Dragons, unicorns and phoenixes</li>
                        <li><strong>Origins: </strong>Deeply tied to the myths, legends, and beliefs of a culture. They often serve symbolic or allegorical purposes.</li>
                        <li><strong>Nature: </strong>Widely understood as symbolic or entirely fictional, rather than animals awaiting discovery.</li>
                    </ul>
                    <br/>
                    <p>The keydifference is that cryptids exist in the grey area between folklore and possible reality, whereas mythical creatures are entirely rooted in the realm of myth and symbolism.</p>
                    <br/><br/>
                    <p><strong>Stories around cryptids and mythical beasts</strong></p>
                    <p>There are some similarities between cryptids and mythical creatures that highlight their roots and mysteries:</p>
                    <br/>
                    <ol className="list">
                        <li><strong>Bigfoot (Cryptid)</strong>
                            <ul>
                                <li><strong>Origins: </strong>Bigfoot, also known as Sasquatch, originates from Indigenous folklore in North America. Tribes like the Lummi and Salish recount stories of a large, hairy, human-like creature living in the wilderness.</li>
                                <li><strong>Endurance: </strong>Sightings and footprints reported over centuries, along with the rugged, unexplored terrain of forests, keep Bigfoot's legend alive.</li>
                            </ul>
                        </li>
                        <li><strong>The Loch Ness Monster (Cryptid)</strong>
                            <ul>
                                <li><strong>Origins: </strong>The earliest mentions of a mysterious water creature in Loch Ness, Scotland, date back to the 6th century, when Saint Columba reportedly encountered a "water beast." Modern interest exploded in the 1930s with alleged photographic evidence.</li>
                                <li><strong>Endurance: </strong>Loch Ness's murky depths and sporadic sightings have sustained curiosity about the creature, nicknamed "Nessie."</li>
                            </ul>
                        </li>
                        <li><strong>Dragons (Mythical)</strong>
                            <ul>
                                <li><strong>Origins: </strong>: Stories of dragons appear in nearly every ancient culture, from Chinese mythology to European folklore. They likely arose from early humans discovering dinosaur fossils and interpreting them as evidence of massive, fearsome reptiles.</li>
                                <li><strong>Endurance: </strong>Dragons symbolise universal themes like power, duality, and triumph over challenges, resonating across cultures and time. Their adaptability in storytelling and modern media keeps them captivating for generations.</li>
                            </ul>
                        </li>
                        <li><strong>The Kraken (Mythical)</strong>
                            <ul>
                                <li><strong>Origins: </strong>Rooted in Scandinavian seafaring tales, the Kraken is a gigantic sea monster, possibly inspired by sightings of giant squids, which can grow up to 43 feet long.</li>
                                <li><strong>Endurance: </strong>Mariners used the Kraken to explain shipwrecks and mysterious disappearances at sea, embedding it into nautical lore.</li>
                            </ul>
                        </li>
                        <li><strong>Chupacabra (Cryptid)</strong>
                            <ul>
                                <li><strong>Origins: </strong>The Chupacabra legend emerged in Puerto Rico in the 1990s after reports of livestock being drained of blood. The name means "goat-sucker." Witnesses describe the creature as reptilian or dog-like.</li>
                                <li><strong>Endurance: </strong>The phenomenon gained widespread media attention, sparking various interpretations of what the Chupacabra might be.</li>
                            </ul>
                        </li>
                    </ol>
                    <br/><br/>
                    <p><strong>Drawing parallels between cryptids and mythical beasts</strong></p>
                    <p>Even though they originate from many geographic locations and traditions, there are fascinating parallels between them that arise from shared human fears and universal storytelling. Natural misinterpretations can also lead to similar tales in different cultures.:</p>
                    <br/>
                    <ol className="list">
                        <li><strong>Humanoid Cryptids</strong>
                            <ul>
                                <li><strong>Examples: </strong>Bigfoot (Cryptid, North America), Yeti (Cryptid, Himalayas), The Woodwose (Mythical Beast, Medieval European folklore)
                                <li><strong>Parallel: </strong>Both are described as large, hairy, human-like creatures living in remote wilderness areas. They are often seen as elusive and mysterious, embodying humanity's fascination with the untamed and unknown.</li>
                                </li>
                            </ul>
                        </li>
                        <li><strong>Aquatic Beasts</strong>
                            <ul>
                                <li><strong>Examples: </strong>The Loch Ness Monster (Cryptid, Scotland) and Ogopogo (Cryptid, Canada), Leviathan (Mythical Beast, Hebrew mythology)
                                <li><strong>Parallel: </strong>These are lake-dwelling creatures often depicted as long, serpent-like beings. Their legends likely stem from ancient fears of the deep and unexplored waters, combined with misidentified sightings of real aquatic animals.</li>
                                </li>
                            </ul>
                        </li>
                        <li><strong>Winged Beings</strong>
                            <ul>
                                <li><strong>Examples: </strong>Mothman (Cryptid, United States), Ahool (Cryptid, Indonesia), Griffin (Mythical Beast, Greek mythology).</li>
                                <li><strong>Parallel: </strong>Winged creatures associated with mystery and awe. They tap into fears of aerial predators and the unknown lurking above. Mothman is seen as a bad omen and the Griffin sybolises guardianship and vigilance. These highlight humanity's mixed feelings about powerful aeriel beings.</li>
                            </ul>
                        </li>
                        <li><strong>Blood-Sucking Beasts</strong>
                            <ul>
                                <li><strong>Examples: </strong>Chupacabra (Cryptid, Latin America), Jersey Devil (Cryptid, United States), Vampire (Mythical Beast, across cultures)</li>
                                <li><strong>Parallel: </strong>These creatures are blamed for livestock deaths and mutilations, tapping into anxieties about threats to livelihood and unexplained animal attacks. They reflect anxieties about predation, loss and the unknown.</li>
                            </ul>
                        </li>
                        <li><strong>Guardians of Sacred or Untouched Land</strong>
                            <ul>
                                <li><strong>Examples: </strong>Bunyip (Cryptid, Australia), Kelpie (Mythical Beast, Scottish folklore).</li>
                                <li><strong>Parallel: </strong>Both are water-dwelling cryptids said to guard natural environments. Their legends often serve as warnings to humans to respect nature or avoid dangerous areas.</li>
                            </ul>
                        </li>
                        <li><strong>Fire-Breathing Legends</strong>
                            <ul>
                                <li><strong>Exmaples: </strong>Mongolian Death Worm (Cryptid, Mongolia), Dragon (Mythical Beast, across cultures)
                                <li><strong>Parallel: </strong>Refelct ancient fears of powerful, deadly creatures beyond human control.</li>
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <br/><br/>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Invent</div>
                <div className="badgePageItemInfo hidden">Invent your own mythical creature. Either draw, paint or make a 3D model of your creature. Think about what it
                eats, where it lives, its temperament and its behavioural patterns.</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I created Eldstjärna (Firestar). I started by imagining what I wanted my creature to look like and then generated appropriate prompts to put into an image generator. I took a suggestion closest to what I wanted and used it as inspiration for my drawing.</p>
                    <br/>
                    <p>I used the ImagineArt image generator, these are some examples of prompts that I used:</p>
                    <ul className="list">
                        <li>an ethereal lady with pale skin, flaming red hair, bright blue eyes, with delicate white transparent wings, wearing earthy coloured trousers and top, barefoot surrounded by nature</li>
                        <li>an ethereal lady with pale skin, flaming red hair, bright blue eyes, with small, delicate,, holographic, transparent wings, wearing natural coloured trousers and top, barefoot sitting near water</li>
                        <li>an ethereal man with pale skin, flaming red hair, bright blue eyes, with small, delicate,, holographic, transparent wings, wearing natural coloured trousers and top, barefoot sitting near water</li>
                        <li>an ethereal being, neither male nor female, with pale skin, flaming red hair, bright blue eyes, with small, delicate,, holographic, transparent wings, wearing natural coloured trousers and top, barefoot sitting near water</li>
                    </ul>
                    <br/><br/>
                    <p>This is the generated image and my final drawing:</p>
                    <div className="gallery">
                        <div className="galleryItem"><img src={DrawingBase} alt="An AI generated image based on prompts for an ethereal being"/></div>
                        <div className="galleryItem"><img src={Drawing} alt="A picture of an ethereal being drawn with coloured pencils"/></div>
                    </div>
                    <br/><br/>
                    <p>My picture is of Mae-Lyn, in their natural female form.</p>
                    <br/>
                    <p>An Eldstjärna is a nature guardian, good at heart but can be fierce if you disrepect their environment. They have no consistent gender and will present however they feel in a situation. They are largely solitary, but fiercely loyal to their kind, happy to join forces as they desire. They are strong and straight-forward, if you're doing no harm they will leave you alone, if you are taking positive actions they will actively help you, if you are causing harm to nature, they will consume you in fire.</p>
                    <br/>
                    <p>Eldstjärnor can fly, shift their looks and survive underwater. They are strong and immortal, eternal protectors. They are naturally invisible to humans and will only be perceptable when they choose to be, either to help people or to manage threats.</p>
                    <br/>
                    <p>They always have fire-red hair, whether presenting long or short. Any clothing or coverings they wear are in natural colours and materials. Their skin is a rainbow of tiny scales which gives them an impenetrable armour and a rainbow-like glow.</p>
                    <br/>
                    <p>They have wings that will present in different sizes, they are irridescent and look delicate but are much stronger than you might imagine.</p>
                    <br/>
                    <p>They don't have a fixed size, they will vary their attributes to their mood and the circumstance in which they are presenting.</p>
                    <br/>
                    <p>They use only natural resources for any activity, food, clothing and coverings, decoration. They can be mischievous when happy, or devastatingly terrifying if angry.</p>
                    <br/><br/>
                    <p>If you are appreciating nature, they will stay hidden but accompany you silently to appreciate your presence and help you to appreciate the natural wonders.</p>
                    <br/>
                    <p>If you are working with nature for healing, you can ask for their help and they will guide you in your practices.</p>
                    <br/>
                    <p>If you are actively harming any part of nature, they will appear before you and you will feel the full fiery effect of their wrath.</p>
                    <br/><br/>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemInfo blue-light">Modern Retellings</div>
                <div className="badgePageItemInfo hidden">Find two modern retellings of the same myth - eg a TV show, film, play or book. After watching or reading, read the 
                original source material and identify the similarities ad differences in the retellings.</div>
                <div className="badgePageItemContent">
                    <p>For this clause I have been looking at the story of Orpheus and Euridice. I used three sources to base my findings on:</p>
                    <br/>
                    <ul className="list">
                        <li>A Song for the Underworld - Book</li>
                        <li>Hadestown - Musical</li>
                        <li>https://archive.vcu.edu/english/engweb/webtexts/eurydice/eurydicemyth.html</li>
                    </ul>
                    <br/><br/>
                    <p>Orpheus and Eurydice - the original myth</p>
                    <ol className="list">
                        <li><strong>Characters: </strong>Orpheus, a gifted musician; Eurydice, his beloved; Hades, ruler of the Underworld; Persephone, Hades' wife.</li>
                        <li><strong>Plot Summary: </strong>
                            <ul>
                                <li>Orpheus and Eurydice are deeply in love</li>
                                <li>Eurydice dies from a snake bite and descends to the Underworld</li>
                                <li>Orpheus follows her and uses his musical talent to charm Hades and Persephone</li>
                                <li>Hades agrees to let Eurydice return with Orpheus on one condition: Orpheus must not look back at Eurydice until they reach the surface</li>
                                <li>Tragically, Orpheus doubts and looks back, causing Eurydice to be lost forever</li>
                            </ul>
                        </li>
                    </ol>
                    <br/>
                    <p>Hadestown the Musical</p>
                    <ol className="list">
                        <li><strong>Setting: </strong>A Depression-era, post-apocalyptic version of the myth, blending New Orleans jazz, folk, and blues music styles.</li>
                        <li><strong>Characters: </strong>Similar to the myth but with added complexity and modern personalities.</li>
                        <li><strong>Plot enhancements: </strong>
                            <ul>
                                <li>Political and Economic Commentary - The musical introduces themes of poverty, labour, and industrialisation, with Hades' Underworld depicted as a harsh, oppressive industrial factory.</li>
                                <li>Character Depth - Hades and Persephone's troubled relationship adds emotional depth and tension. Orpheus is portrayed as an idealistic dreamer, while Eurydice is more pragmatic, influenced by the harshness of life.</li>
                                <li>Narration and Fourth Wall - The character of Hermes serves as a narrator, directly engaging with the audience and adding a layer of storytelling</li>
                                <li>Musical Elements - The musical features a variety of songs that explore the characters' inner thoughts and the world's struggles, adding emotional resonance. The workers' chorus adds a collective voice to the narrative, representing the suffering and hopes of the masses.</li>
                            </ul>
                        </li>
                    </ol>
                    <br/><br/>
                    <p><strong>A Song for the Underworld - Adam Alexander Haviaras</strong></p>
                    <ol className="list">
                        <li><strong>Setting: </strong>Ancient Greece, with a focus on the political and social turmoil of the time.</li>
                        <li><strong>Characters: </strong>Similar to the myth but with added complexity and modern personalities.</li>
                        <li><strong>Plot enhancements: </strong>
                            <ul>
                                <li>Expanded Backstory - The book delves into Orpheus' upbringing by the daughters of Zeus and his journey to discover his destiny.</li>
                                <li>Political and Social Context - The story is set against a backdrop of war and suffering, adding layers of historical and social commentary.</li>
                                <li>Character Development - Orpheus is portrayed as a humble yet immensely talented musician who inspires mortals, animals, and gods with his music. Eurydice is depicted as a nymph whose love and friendship elevate Orpheus' music to new heights.</li>
                                <li>Emotional Depth - The book explores the cruelty of the Fates and the harshness of the world, emphasising the challenges Orpheus faces in his quest to rescue Eurydice.</li>
                                <li>Heroic Journey - Orpheus' journey to the Underworld is depicted as a terrifying and epic quest, requiring him to confront his deepest emotions and play like never before.</li>
                            </ul>
                        </li>
                    </ol>
                    <br/><br/>
                    <p>Hadestown keeps the main elements of the myth, but enriches the narrative with contemporary themes, character development and an amazing musical score.</p>
                    <br/>
                    <p>Again, A Song for the Underworld keeps the main elements of the myth but enriches it with historical context, character development and emotional depth.</p>
                    <br/><br/>
                    <p>Here are some notes from the author of A Song for the Underworld that I found interesting.</p>
                    <br/>
                    <p>Elements from the story were taken from:</p>
                    <ul className="list">
                        <li>Euripedes' play, Alcestis - one of the earliest retellings</li>
                        <li>Virgil Georgics</li>
                        <li>Ovid Metamorphoses</li>
                        <li>Pindar - the Pythian odes</li>
                        <li>Plato - Symposium</li>
                        <li>Apollonius of Rhodes - Argonatucia</li>
                        <li>Diodorus Siculus</li>
                        <li>Pausanias</li>
                    </ul>
                    <br/>
                    <p>Places used that are tied to the story historically:</p>
                    <ul className="list">
                        <li>The sacred spring of Hippocrene on Mout Helicon</li>
                        <li>The ancient region of Pieria, around the base of Mount Olympus</li>
                        <li>The cape of Tainaron I the southern Peloponnese</li>
                        <li>Thrace</li>
                    </ul>
                    <br/>
                    <p>Relationships:</p>
                    <ul className="list">
                        <li>Stories of Orpheus' father vary. Some say King Oeagrus of Thrace, some say Apollo. Author hinted at both but ultimately went with Apollo</li>
                    </ul>
                    <br/>
                    <p>Cessation of Music</p>
                    <ul className="list">
                        <li>Stories say that the Maenads tore Orpheus to pieces - possibly because they wanted him for themselves and fought over him</li>
                        <li>Some say that Dionysus turns against him because he honoured Helios over him</li>
                        <li>Author made it so that Orpheus ceasing to play music was the cause of his death at the hands of the Maenads</li>
                    </ul>
                </div>
            </div>    
        </div>
    )
}

export default Mythology