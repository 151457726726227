import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedMixologist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Mixologist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Cocktail 1</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden">Make cocktails, learn about origin, try more than one flavour variant where possible.
                Amaretto Sour, Moscow Mule, Zombie, Daiquiri, Passionfruit Martini, Bramble, Butter Beer, Sex on the Beach, Old Fashioned, Manhattan, Tom Collins, Singapore Sling</div>
                <div className="badgePageItemContent">
                    
                    https://easygoodideas.com/virgin-tom-collins/
                    https://www.allrecipes.com/recipe/107945/virgin-strawberry-daiquiri/
                    https://www.platingsandpairings.com/bramble-cocktail/
                    https://www.yummymummykitchen.com/2019/12/moscow-mule-mocktail-recipe.html
                    https://www.bbcgoodfood.com/recipes/alcohol-free-amaretto-sour
                    https://cocktailconnexion.com/en-int/pages/virgin-zombie-mocktail?srsltid=AfmBOorRb9mMzjDCczRZfbfMu7_y6ssT7xvZoTVY4PxIiJm09eD6ul76
                    

                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Cocktail 2</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Cocktail 3</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Cocktail 4</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Cocktail 5</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Cocktail 6</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedMixologist