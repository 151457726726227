import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  LiftOff25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Lift Off 25"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Badge Planning</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Choose 6 badges - one from each section - make a plan of how and when you will achieve them, complete 2 this quarter.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Creative - Advanced Artist. To be finished within 12 months.</p>
                        <p>Global - <a href="/mythology">Mythology</a>. Finished in March 2025.</p>
                        <p>Grown Up - <a href="/librarian">Librarian</a>. Finished in January 2025, also earning Grown Up patch.</p>
                        <p>Self Aware - Advanced Goals. To be finished within 12 months.</p>
                        <p>Wellness Rebel - Advanced Diarist. To be finished within 12 months.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Advanced Badge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Choose an advanced badge, complete the first badge then make plans to complete the advanced one this year.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I am choosing Advanced Animal lover for this.  I completed <a href="/animallover">Animal Lover</a> in January 2025 and <a href="AdvancedAnimalLover">Advanced Animal Lover</a> in February 2025.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Annual Badge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Choose at least one of the year-long annual badges and start working towards it.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I will be working towards the More Exercise badge this year.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">General Goals</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Choose at least 3 general goals for 2025, break down into steps, some of which can be achieved this quarter.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>My general goals are below but I'm not sharing the breakdown here. I am using various apps and tools to keep myself on track with all my goals for the year.</p>
                        <ul className="list">
                            <li>More Exercise - goal for small weight loss and increased fitness and flexibility</li>
                            <li>More Fun - get out and about and do more things</li>
                            <li>More Knowledge - keep learning new things across many subjects and keep up to date with technology</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiftOff25