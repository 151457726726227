import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Rebel from "../images/rebel-logo.png";

const Book3 = () => {

useEffect(() => {
        const elements = document.querySelectorAll(".badgeItem");
        var currDate = new Date();

        elements.forEach((el) => {
            var oVal = 0;
            var oProg;
            var mProg;
            var count =  el.querySelectorAll(".badgeClauseStatus").length;
            var maxval = 100 / count;

            el.querySelectorAll(".badgeClauseStatus").forEach((elS) => {
                var cID = elS.id;
                var cStatus = elS.textContent;
                var cStart = el.querySelector("#" + cID.replace("Status","Start")).textContent;
                var cEnd = el.querySelector("#" + cID.replace("Status","End")).textContent;
                var cProg = el.querySelector("#" + cID.replace("Status","Progress"));
                oProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "Progress");
                mProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "ProgressMini");
                
                var duration = cProg.getAttribute("data-duration");

                if(duration != null)
                {
                    duration = parseInt(duration) * 31;
                }
                
                if (cStatus === "In progress")
                {
                    if (cEnd === "" && cStart !== "")
                    {
                        var startString = cStart.split("/");
                        var startDate = new Date(startString[2],startString[1]-1,startString[0]);
                        var diff = new Date(startDate - currDate);
                        var days =  Math.ceil(diff/1000/60/60/24);
                        var maxdays = -90;

                        if(duration != null) 
                        {
                            maxdays = -duration;
                        }
                        
                        cProg.value = (Math.ceil(days/maxdays*100));

                        var setP = days * maxdays / 100;

                        if(setP > maxval)
                        {
                            setP = maxval / 2;
                        }
                        oVal = oVal + setP;

                        cProg.classList.add("orange");
                        cProg.classList.remove("green");
                        cProg.classList.remove("red");
                    }
                    else if (cEnd !== "" && cStart !== "")
                    {
                        cProg.value = 100;
                        cProg.classList.add("green");
                        cProg.classList.remove("orange");
                        cProg.classList.remove("red");
                        oVal = oVal + maxval;
                    }
                }
                else if (cStatus === "Complete")
                {
                    cProg.value = 100;
                    cProg.classList.add("green");
                    cProg.classList.remove("orange");
                    cProg.classList.remove("red");
                    oVal = oVal + maxval;
                }
                else if (cStatus === "Not started")
                {
                    cProg.value = 1;
                    cProg.classList.remove("green");
                    cProg.classList.remove("orange");
                    cProg.classList.add("red");
                    oVal = oVal + 0;
                }
            })

            var oCalc = Math.ceil(oVal);
            oProg.value = oCalc;
            mProg.value = oCalc;

            
            if(oCalc <= 1)
            {
                oProg.classList.add("red");
                oProg.classList.remove("orange");
                oProg.classList.remove("green");
                mProg.classList.add("red");
                mProg.classList.remove("orange");
                mProg.classList.remove("green");
            }
            else if (oCalc < 100)
            {
                oProg.classList.remove("red");
                oProg.classList.add("orange");
                oProg.classList.remove("green");
                mProg.classList.remove("red");
                mProg.classList.add("orange");
                mProg.classList.remove("green");
            }
            else
            {
                oProg.classList.remove("red");
                oProg.classList.remove("orange");
                oProg.classList.add("green");
                mProg.classList.remove("red");
                mProg.classList.remove("orange");
                mProg.classList.add("green");
            }    
        })


        document.querySelectorAll(".badgeItemIntro").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".badgeClause").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".badgeProgress").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".icon-down-open").forEach((elI) => {elI.classList.add("shown")})
        document.querySelectorAll(".icon-up-open").forEach((elI) => {elI.classList.add("hidden")})

        document.querySelectorAll(".badgeItemTitle").forEach((mit) => {addListener(mit);})

    }, [])

    return (
        <div>
            <div className="actionBar">
                <div className="actionBarItem"><a href="./">Back to Dashboard</a></div>
            </div>
            <div className="row bookPageTop">
                <div className="rebelLeft"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookLeft" /></div>
                <div className="rebelMid">
                    <h2>Rebel Badge Book Three</h2>
                </div>
                <div className="rebelRight"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookRight" /></div>
            </div>
            <div className="row pageIntroduction">
                <p><br/>This page follows my progress in completing the badges in the third Rebel Badge Book. It doesn't detail the entire contents of the book, just the badges and options that I have chosen from those offered.</p>
                <p><br/>I have intentionally abbreviated or removed the clauses to protect the content of the book - if you want to see the full details, go and buy the <a className="linkUnderline"  href="https://www.rebelbadgestore.com/" target="_blank" rel="noopener noreferrer">Rebel Badge Book</a> &#x1F609;</p>
            </div>
           
            <div className="bookSectionTitle bstpurple">Creative Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Artist</div>
                        <div>
                            <progress id="artistProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">3 different projects<br/><Link to="/advancedartist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="artist1Status">Not started</div>
                        <div className="badgeClauseStart" id="artist1Start"></div>
                        <div className="badgeClauseEnd" id="artist1End"></div>
                        <div className="badgeClauseProgress"><progress id="artist1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="artist2Status">Not started</div>
                        <div className="badgeClauseStart" id="artist2Start"></div>
                        <div className="badgeClauseEnd" id="artist2End"></div>
                        <div className="badgeClauseProgress"><progress id="artist2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="artist3Status">Not started</div>
                        <div className="badgeClauseStart" id="artist3Start"></div>
                        <div className="badgeClauseEnd" id="artist3End"></div>
                        <div className="badgeClauseProgress"><progress id="artist3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="artistProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Cake Artist</div>
                        <div>
                            <progress id="cakeProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">8 different projects<br/><Link to="/cakeartist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="cake1Status">Not started</div>
                        <div className="badgeClauseStart" id="cake1Start"></div>
                        <div className="badgeClauseEnd" id="cake1End"></div>
                        <div className="badgeClauseProgress"><progress id="cake1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="cake2Status">Not started</div>
                        <div className="badgeClauseStart" id="cake2Start"></div>
                        <div className="badgeClauseEnd" id="cake2End"></div>
                        <div className="badgeClauseProgress"><progress id="cake2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="cake3Status">Not started</div>
                        <div className="badgeClauseStart" id="cake3Start"></div>
                        <div className="badgeClauseEnd" id="cake3End"></div>
                        <div className="badgeClauseProgress"><progress id="cake3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 4</div>
                        <div className="badgeClauseStatus" id="cake4Status">Not started</div>
                        <div className="badgeClauseStart" id="cake4Start"></div>
                        <div className="badgeClauseEnd" id="cake4End"></div>
                        <div className="badgeClauseProgress"><progress id="cake4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 5</div>
                        <div className="badgeClauseStatus" id="cake5Status">Not started</div>
                        <div className="badgeClauseStart" id="cake5Start"></div>
                        <div className="badgeClauseEnd" id="cake5End"></div>
                        <div className="badgeClauseProgress"><progress id="cake5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 6</div>
                        <div className="badgeClauseStatus" id="cake6Status">Not started</div>
                        <div className="badgeClauseStart" id="cake6Start"></div>
                        <div className="badgeClauseEnd" id="cake6End"></div>
                        <div className="badgeClauseProgress"><progress id="cake6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 7</div>
                        <div className="badgeClauseStatus" id="cake7Status">Not started</div>
                        <div className="badgeClauseStart" id="cake7Start"></div>
                        <div className="badgeClauseEnd" id="cake7End"></div>
                        <div className="badgeClauseProgress"><progress id="cake7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 8</div>
                        <div className="badgeClauseStatus" id="cake8Status">Not started</div>
                        <div className="badgeClauseStart" id="cake8Start"></div>
                        <div className="badgeClauseEnd" id="cake8End"></div>
                        <div className="badgeClauseProgress"><progress id="cake8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="cakeProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Carpenter</div>
                        <div>
                            <progress id="carpenterProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">7 different projects<br/><Link to="/carpenter">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="carpenter1Status">Not started</div>
                        <div className="badgeClauseStart" id="carpenter1Start"></div>
                        <div className="badgeClauseEnd" id="carpenter1End"></div>
                        <div className="badgeClauseProgress"><progress id="carpenter1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="carpenter2Status">Not started</div>
                        <div className="badgeClauseStart" id="carpenter2Start"></div>
                        <div className="badgeClauseEnd" id="carpenter2End"></div>
                        <div className="badgeClauseProgress"><progress id="carpenter2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="carpenter3Status">Not started</div>
                        <div className="badgeClauseStart" id="carpenter3Start"></div>
                        <div className="badgeClauseEnd" id="carpenter3End"></div>
                        <div className="badgeClauseProgress"><progress id="carpenter3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 4</div>
                        <div className="badgeClauseStatus" id="carpenter4Status">Not started</div>
                        <div className="badgeClauseStart" id="carpenter4Start"></div>
                        <div className="badgeClauseEnd" id="carpenter4End"></div>
                        <div className="badgeClauseProgress"><progress id="carpenter4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 5</div>
                        <div className="badgeClauseStatus" id="carpenter5Status">Not started</div>
                        <div className="badgeClauseStart" id="carpenter5Start"></div>
                        <div className="badgeClauseEnd" id="carpenter5End"></div>
                        <div className="badgeClauseProgress"><progress id="carpenter5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 6</div>
                        <div className="badgeClauseStatus" id="carpenter6Status">Not started</div>
                        <div className="badgeClauseStart" id="carpenter6Start"></div>
                        <div className="badgeClauseEnd" id="carpenter6End"></div>
                        <div className="badgeClauseProgress"><progress id="carpenter6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 7</div>
                        <div className="badgeClauseStatus" id="carpenter7Status">Not started</div>
                        <div className="badgeClauseStart" id="carpenter7Start"></div>
                        <div className="badgeClauseEnd" id="carpenter7End"></div>
                        <div className="badgeClauseProgress"><progress id="carpenter7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="carpenterProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Graphic Designer</div>
                        <div>
                            <progress id="graphicProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">6 different projects<br/><Link to="/graphicdesigner">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="graphic1Status">Not started</div>
                        <div className="badgeClauseStart" id="graphic1Start"></div>
                        <div className="badgeClauseEnd" id="graphic1End"></div>
                        <div className="badgeClauseProgress"><progress id="graphic1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="graphic2Status">Not started</div>
                        <div className="badgeClauseStart" id="graphic2Start"></div>
                        <div className="badgeClauseEnd" id="graphic2End"></div>
                        <div className="badgeClauseProgress"><progress id="graphic2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="graphic3Status">Not started</div>
                        <div className="badgeClauseStart" id="graphic3Start"></div>
                        <div className="badgeClauseEnd" id="graphic3End"></div>
                        <div className="badgeClauseProgress"><progress id="graphic3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 4</div>
                        <div className="badgeClauseStatus" id="graphic4Status">Not started</div>
                        <div className="badgeClauseStart" id="graphic4Start"></div>
                        <div className="badgeClauseEnd" id="graphic4End"></div>
                        <div className="badgeClauseProgress"><progress id="graphic4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 5</div>
                        <div className="badgeClauseStatus" id="graphic5Status">Not started</div>
                        <div className="badgeClauseStart" id="graphic5Start"></div>
                        <div className="badgeClauseEnd" id="graphic5End"></div>
                        <div className="badgeClauseProgress"><progress id="graphic5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 6</div>
                        <div className="badgeClauseStatus" id="graphic6Status">Not started</div>
                        <div className="badgeClauseStart" id="graphic6Start"></div>
                        <div className="badgeClauseEnd" id="graphic6End"></div>
                        <div className="badgeClauseProgress"><progress id="graphic6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="graphicProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Singer</div>
                        <div>
                            <progress id="singerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">5 clauses<br/><Link to="/singer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="singer1Status">Complete</div>
                        <div className="badgeClauseStart" id="singer1Start">1/1/2024</div>
                        <div className="badgeClauseEnd" id="singer1End">1/1/2024</div>
                        <div className="badgeClauseProgress"><progress id="singer1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="singer2Status">Complete</div>
                        <div className="badgeClauseStart" id="singer2Start">1/1/2024</div>
                        <div className="badgeClauseEnd" id="singer2End">1/1/2024</div>
                        <div className="badgeClauseProgress"><progress id="singer2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="singer3Status">Complete</div>
                        <div className="badgeClauseStart" id="singer3Start">1/1/2024</div>
                        <div className="badgeClauseEnd" id="singer3End">1/1/2024</div>
                        <div className="badgeClauseProgress"><progress id="singer3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="singer4Status">Complete</div>
                        <div className="badgeClauseStart" id="singer4Start">1/1/2024</div>
                        <div className="badgeClauseEnd" id="singer4End">1/1/2024</div>
                        <div className="badgeClauseProgress"><progress id="singer4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="singer5Status">Complete</div>
                        <div className="badgeClauseStart" id="singer5Start">1/1/2024</div>
                        <div className="badgeClauseEnd" id="singer5End">1/1/2024</div>
                        <div className="badgeClauseProgress"><progress id="singer5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    
                    <div className="badgeProgress"><progress id="singerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Upcycler</div>
                        <div>
                            <progress id="upcyclerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">5 different projects<br/><Link to="/upcycler">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="upcycler1Status">Not started</div>
                        <div className="badgeClauseStart" id="upcycler1Start"></div>
                        <div className="badgeClauseEnd" id="upcycler1End"></div>
                        <div className="badgeClauseProgress"><progress id="upcycler1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="upcycler2Status">Not started</div>
                        <div className="badgeClauseStart" id="upcycler2Start"></div>
                        <div className="badgeClauseEnd" id="upcycler2End"></div>
                        <div className="badgeClauseProgress"><progress id="upcycler2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="upcycler3Status">Not started</div>
                        <div className="badgeClauseStart" id="upcycler3Start"></div>
                        <div className="badgeClauseEnd" id="upcycler3End"></div>
                        <div className="badgeClauseProgress"><progress id="upcycler3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 4</div>
                        <div className="badgeClauseStatus" id="upcycler4Status">Not started</div>
                        <div className="badgeClauseStart" id="upcycler4Start"></div>
                        <div className="badgeClauseEnd" id="upcycler4End"></div>
                        <div className="badgeClauseProgress"><progress id="upcycler4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 5</div>
                        <div className="badgeClauseStatus" id="upcycler5Status">Not started</div>
                        <div className="badgeClauseStart" id="upcycler5Start"></div>
                        <div className="badgeClauseEnd" id="upcycler5End"></div>
                        <div className="badgeClauseProgress"><progress id="upcycler5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="upcyclerProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstblue">Global Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Animal Lover</div>
                        <div>
                            <progress id="animalProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">5 different clauses<br/><Link to="/advancedanimallover">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="animal1Status">Complete</div>
                        <div className="badgeClauseStart" id="animal1Start">1/2/25</div>
                        <div className="badgeClauseEnd" id="animal1End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="animal1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="animal2Status">Complete</div>
                        <div className="badgeClauseStart" id="animal2Start">1/2/25</div>
                        <div className="badgeClauseEnd" id="animal2End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="animal2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="animal3Status">Complete</div>
                        <div className="badgeClauseStart" id="animal3Start">10/1/2025</div>
                        <div className="badgeClauseEnd" id="animal3End">10/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="animal3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="animal4Status">Complete</div>
                        <div className="badgeClauseStart" id="animal4Start">1/2/25</div>
                        <div className="badgeClauseEnd" id="animal4End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="animal4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="animal5Status">Complete</div>
                        <div className="badgeClauseStart" id="animal5Start">1/2/25</div>
                        <div className="badgeClauseEnd" id="animal5End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="animal5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="animalProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Classicist</div>
                        <div>
                            <progress id="classicistProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">6 different clauses<br/><Link to="/classicist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="classicist1Status">Not started</div>
                        <div className="badgeClauseStart" id="classicist1Start"></div>
                        <div className="badgeClauseEnd" id="classicist1End"></div>
                        <div className="badgeClauseProgress"><progress id="classicist1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="classicist2Status">Not started</div>
                        <div className="badgeClauseStart" id="classicist2Start"></div>
                        <div className="badgeClauseEnd" id="classicist2End"></div>
                        <div className="badgeClauseProgress"><progress id="classicist2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="classicist3Status">Not started</div>
                        <div className="badgeClauseStart" id="classicist3Start"></div>
                        <div className="badgeClauseEnd" id="classicist3End"></div>
                        <div className="badgeClauseProgress"><progress id="classicist3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="classicist4Status">Not started</div>
                        <div className="badgeClauseStart" id="classicist4Start"></div>
                        <div className="badgeClauseEnd" id="classicist4End"></div>
                        <div className="badgeClauseProgress"><progress id="classicist4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="classicist5Status">Not started</div>
                        <div className="badgeClauseStart" id="classicist5Start"></div>
                        <div className="badgeClauseEnd" id="classicist5End"></div>
                        <div className="badgeClauseProgress"><progress id="classicist5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="classicist6Status">Not started</div>
                        <div className="badgeClauseStart" id="classicist6Start"></div>
                        <div className="badgeClauseEnd" id="classicist6End"></div>
                        <div className="badgeClauseProgress"><progress id="classicist6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="classicistProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Current Affairs</div>
                        <div>
                            <progress id="affairsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">7 different clauses<br/><Link to="/currentaffairs">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="affairs1Status">Not started</div>
                        <div className="badgeClauseStart" id="affairs1Start"></div>
                        <div className="badgeClauseEnd" id="affairs1End"></div>
                        <div className="badgeClauseProgress"><progress id="affairs1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="affairs2Status">Not started</div>
                        <div className="badgeClauseStart" id="affairs2Start"></div>
                        <div className="badgeClauseEnd" id="affairs2End"></div>
                        <div className="badgeClauseProgress"><progress id="affairs2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="affairs3Status">Not started</div>
                        <div className="badgeClauseStart" id="affairs3Start"></div>
                        <div className="badgeClauseEnd" id="affairs3End"></div>
                        <div className="badgeClauseProgress"><progress id="affairs3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="affairs4Status">Not started</div>
                        <div className="badgeClauseStart" id="affairs4Start"></div>
                        <div className="badgeClauseEnd" id="affairs4End"></div>
                        <div className="badgeClauseProgress"><progress id="affairs4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="affairs5Status">Not started</div>
                        <div className="badgeClauseStart" id="affairs5Start"></div>
                        <div className="badgeClauseEnd" id="affairs5End"></div>
                        <div className="badgeClauseProgress"><progress id="affairs5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="affairs6Status">Not started</div>
                        <div className="badgeClauseStart" id="affairs6Start"></div>
                        <div className="badgeClauseEnd" id="affairs6End"></div>
                        <div className="badgeClauseProgress"><progress id="affairs6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="affairs7Status">Not started</div>
                        <div className="badgeClauseStart" id="affairs7Start"></div>
                        <div className="badgeClauseEnd" id="affairs7End"></div>
                        <div className="badgeClauseProgress"><progress id="affairs7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="affairsProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Local History</div>
                        <div>
                            <progress id="historyProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">9 different clauses<br/><Link to="/localhistory">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="history1Status">Not started</div>
                        <div className="badgeClauseStart" id="history1Start"></div>
                        <div className="badgeClauseEnd" id="history1End"></div>
                        <div className="badgeClauseProgress"><progress id="history1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="history2Status">Not started</div>
                        <div className="badgeClauseStart" id="history2Start"></div>
                        <div className="badgeClauseEnd" id="history2End"></div>
                        <div className="badgeClauseProgress"><progress id="history2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="history3Status">Not started</div>
                        <div className="badgeClauseStart" id="history3Start"></div>
                        <div className="badgeClauseEnd" id="history3End"></div>
                        <div className="badgeClauseProgress"><progress id="history3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="history4Status">Not started</div>
                        <div className="badgeClauseStart" id="history4Start"></div>
                        <div className="badgeClauseEnd" id="history4End"></div>
                        <div className="badgeClauseProgress"><progress id="history4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="history5Status">Not started</div>
                        <div className="badgeClauseStart" id="history5Start"></div>
                        <div className="badgeClauseEnd" id="history5End"></div>
                        <div className="badgeClauseProgress"><progress id="history5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="history6Status">Not started</div>
                        <div className="badgeClauseStart" id="history6Start"></div>
                        <div className="badgeClauseEnd" id="history6End"></div>
                        <div className="badgeClauseProgress"><progress id="history6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="history7Status">Not started</div>
                        <div className="badgeClauseStart" id="history7Start"></div>
                        <div className="badgeClauseEnd" id="history7End"></div>
                        <div className="badgeClauseProgress"><progress id="history7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="history8Status">Not started</div>
                        <div className="badgeClauseStart" id="history8Start"></div>
                        <div className="badgeClauseEnd" id="history8End"></div>
                        <div className="badgeClauseProgress"><progress id="history8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 9</div>
                        <div className="badgeClauseStatus" id="history9Status">Not started</div>
                        <div className="badgeClauseStart" id="history9Start"></div>
                        <div className="badgeClauseEnd" id="history9End"></div>
                        <div className="badgeClauseProgress"><progress id="history9Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 10</div>
                        <div className="badgeClauseStatus" id="history10Status">Not started</div>
                        <div className="badgeClauseStart" id="history10Start"></div>
                        <div className="badgeClauseEnd" id="history10End"></div>
                        <div className="badgeClauseProgress"><progress id="history10Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 11</div>
                        <div className="badgeClauseStatus" id="history11Status">Not started</div>
                        <div className="badgeClauseStart" id="history11Start"></div>
                        <div className="badgeClauseEnd" id="history11End"></div>
                        <div className="badgeClauseProgress"><progress id="history11Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="historyProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Mythology</div>
                        <div>
                            <progress id="mythologyProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">6 different clauses<br/><Link to="/mythology">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="mythology1Status">Complete</div>
                        <div className="badgeClauseStart" id="mythology1Start">1/1/2025</div>
                        <div className="badgeClauseEnd" id="mythology1End">31/3/2025</div>
                        <div className="badgeClauseProgress"><progress id="mythology1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="mythology2Status">Complete</div>
                        <div className="badgeClauseStart" id="mythology2Start">1/1/2025</div>
                        <div className="badgeClauseEnd" id="mythology2End">31/3/2025</div>
                        <div className="badgeClauseProgress"><progress id="mythology2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="mythology3Status">Complete</div>
                        <div className="badgeClauseStart" id="mythology3Start">1/1/2025</div>
                        <div className="badgeClauseEnd" id="mythology3End"></div>
                        <div className="badgeClauseProgress"><progress id="mythology3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="mythology4Status">Complete</div>
                        <div className="badgeClauseStart" id="mythology4Start">1/1/2025</div>
                        <div className="badgeClauseEnd" id="mythology4End">31/3/2025</div>
                        <div className="badgeClauseProgress"><progress id="mythology4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="mythology5Status">Complete</div>
                        <div className="badgeClauseStart" id="mythology5Start">1/1/2025</div>
                        <div className="badgeClauseEnd" id="mythology5End">31/3/2025</div>
                        <div className="badgeClauseProgress"><progress id="mythology5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="mythology6Status">Complete</div>
                        <div className="badgeClauseStart" id="mythology6Start">1/1/2025</div>
                        <div className="badgeClauseEnd" id="mythology6End">31/3/2025</div>
                        <div className="badgeClauseProgress"><progress id="mythology6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="mythologyProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Rebel Community</div>
                        <div>
                            <progress id="rebelcommunityProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">6 clauses<br/><Link to="/rebelcommunity">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="rebelcommunity1Status">Complete</div>
                        <div className="badgeClauseStart" id="rebelcommunity1Start">01/01/2025</div>
                        <div className="badgeClauseEnd" id="rebelcommunity1End">31/01/2025</div>
                        <div className="badgeClauseProgress"><progress id="rebelcommunity1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="rebelcommunity2Status">Complete</div>
                        <div className="badgeClauseStart" id="rebelcommunity2Start">01/01/2025</div>
                        <div className="badgeClauseEnd" id="rebelcommunity2End">31/01/2025</div>
                        <div className="badgeClauseProgress"><progress id="rebelcommunity2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="rebelcommunity3Status">Complete</div>
                        <div className="badgeClauseStart" id="rebelcommunity3Start">01/01/2025</div>
                        <div className="badgeClauseEnd" id="rebelcommunity3End">31/01/2025</div>
                        <div className="badgeClauseProgress"><progress id="rebelcommunity3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="rebelcommunity4Status">Complete</div>
                        <div className="badgeClauseStart" id="rebelcommunity4Start">01/01/2025</div>
                        <div className="badgeClauseEnd" id="rebelcommunity4End">31/01/2025</div>
                        <div className="badgeClauseProgress"><progress id="rebelcommunity4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="rebelcommunity5Status">Complete</div>
                        <div className="badgeClauseStart" id="rebelcommunity5Start">01/01/2025</div>
                        <div className="badgeClauseEnd" id="rebelcommunity5End">31/01/2025</div>
                        <div className="badgeClauseProgress"><progress id="rebelcommunity5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="rebelcommunity6Status">Complete</div>
                        <div className="badgeClauseStart" id="rebelcommunity6Start">01/01/2025</div>
                        <div className="badgeClauseEnd" id="rebelcommunity6End">31/01/2025</div>
                        <div className="badgeClauseProgress"><progress id="rebelcommunity6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="rebelcommunityProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            
            <div className="bookSectionTitle bstolive">Grown Up Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Baker</div>
                        <div>
                            <progress id="bakerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">9 different clauses<br/><Link to="/advancedbaker">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="baker1Status">Not started</div>
                        <div className="badgeClauseStart" id="baker1Start"></div>
                        <div className="badgeClauseEnd" id="baker1End"></div>
                        <div className="badgeClauseProgress"><progress id="baker1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="baker2Status">Not started</div>
                        <div className="badgeClauseStart" id="baker2Start"></div>
                        <div className="badgeClauseEnd" id="baker2End"></div>
                        <div className="badgeClauseProgress"><progress id="baker2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="baker3Status">Not started</div>
                        <div className="badgeClauseStart" id="baker3Start"></div>
                        <div className="badgeClauseEnd" id="baker3End"></div>
                        <div className="badgeClauseProgress"><progress id="baker3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="baker4Status">Not started</div>
                        <div className="badgeClauseStart" id="baker4Start"></div>
                        <div className="badgeClauseEnd" id="baker4End"></div>
                        <div className="badgeClauseProgress"><progress id="baker4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="baker5Status">Not started</div>
                        <div className="badgeClauseStart" id="baker5Start"></div>
                        <div className="badgeClauseEnd" id="baker5End"></div>
                        <div className="badgeClauseProgress"><progress id="baker5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="baker6Status">Not started</div>
                        <div className="badgeClauseStart" id="baker6Start"></div>
                        <div className="badgeClauseEnd" id="baker6End"></div>
                        <div className="badgeClauseProgress"><progress id="baker6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="baker7Status">Not started</div>
                        <div className="badgeClauseStart" id="baker7Start"></div>
                        <div className="badgeClauseEnd" id="baker7End"></div>
                        <div className="badgeClauseProgress"><progress id="baker7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="baker8Status">Not started</div>
                        <div className="badgeClauseStart" id="baker8Start"></div>
                        <div className="badgeClauseEnd" id="baker8End"></div>
                        <div className="badgeClauseProgress"><progress id="baker8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 9</div>
                        <div className="badgeClauseStatus" id="baker9Status">Not started</div>
                        <div className="badgeClauseStart" id="baker9Start"></div>
                        <div className="badgeClauseEnd" id="baker9End"></div>
                        <div className="badgeClauseProgress"><progress id="baker9Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="bakerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Detective</div>
                        <div>
                            <progress id="detectiveProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">12 different projects<br/><Link to="/advanceddetective">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="detective1Status">Not started</div>
                        <div className="badgeClauseStart" id="detective1Start"></div>
                        <div className="badgeClauseEnd" id="detective1End"></div>
                        <div className="badgeClauseProgress"><progress id="detective1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="detective2Status">Not started</div>
                        <div className="badgeClauseStart" id="detective2Start"></div>
                        <div className="badgeClauseEnd" id="detective2End"></div>
                        <div className="badgeClauseProgress"><progress id="detective2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="detective3Status">Not started</div>
                        <div className="badgeClauseStart" id="detective3Start"></div>
                        <div className="badgeClauseEnd" id="detective3End"></div>
                        <div className="badgeClauseProgress"><progress id="detective3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 4</div>
                        <div className="badgeClauseStatus" id="detective4Status">Not started</div>
                        <div className="badgeClauseStart" id="detective4Start"></div>
                        <div className="badgeClauseEnd" id="detective4End"></div>
                        <div className="badgeClauseProgress"><progress id="detective4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 5</div>
                        <div className="badgeClauseStatus" id="detective5Status">Not started</div>
                        <div className="badgeClauseStart" id="detective5Start"></div>
                        <div className="badgeClauseEnd" id="detective5End"></div>
                        <div className="badgeClauseProgress"><progress id="detective5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 6</div>
                        <div className="badgeClauseStatus" id="detective6Status">Not started</div>
                        <div className="badgeClauseStart" id="detective6Start"></div>
                        <div className="badgeClauseEnd" id="detective6End"></div>
                        <div className="badgeClauseProgress"><progress id="detective6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 7</div>
                        <div className="badgeClauseStatus" id="detective7Status">Not started</div>
                        <div className="badgeClauseStart" id="detective7Start"></div>
                        <div className="badgeClauseEnd" id="detective7End"></div>
                        <div className="badgeClauseProgress"><progress id="detective7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 8</div>
                        <div className="badgeClauseStatus" id="detective8Status">Not started</div>
                        <div className="badgeClauseStart" id="detective8Start"></div>
                        <div className="badgeClauseEnd" id="detective8End"></div>
                        <div className="badgeClauseProgress"><progress id="detective8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 9</div>
                        <div className="badgeClauseStatus" id="detective9Status">Not started</div>
                        <div className="badgeClauseStart" id="detective9Start"></div>
                        <div className="badgeClauseEnd" id="detective9End"></div>
                        <div className="badgeClauseProgress"><progress id="detective9Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 10</div>
                        <div className="badgeClauseStatus" id="detective10Status">Not started</div>
                        <div className="badgeClauseStart" id="detective10Start"></div>
                        <div className="badgeClauseEnd" id="detective10End"></div>
                        <div className="badgeClauseProgress"><progress id="detective10Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 11</div>
                        <div className="badgeClauseStatus" id="detective11Status">Not started</div>
                        <div className="badgeClauseStart" id="detective11Start"></div>
                        <div className="badgeClauseEnd" id="detective11End"></div>
                        <div className="badgeClauseProgress"><progress id="detective11Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 12</div>
                        <div className="badgeClauseStatus" id="detective12Status">Not started</div>
                        <div className="badgeClauseStart" id="detective12Start"></div>
                        <div className="badgeClauseEnd" id="detective12End"></div>
                        <div className="badgeClauseProgress"><progress id="detective12Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="detectiveProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Mixologist</div>
                        <div>
                            <progress id="mixProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">6 different clauses<br/><Link to="/advancedmixologist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="mix1Status">Not started</div>
                        <div className="badgeClauseStart" id="mix1Start"></div>
                        <div className="badgeClauseEnd" id="mix1End"></div>
                        <div className="badgeClauseProgress"><progress id="mix1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="mix2Status">Not started</div>
                        <div className="badgeClauseStart" id="mix2Start"></div>
                        <div className="badgeClauseEnd" id="mix2End"></div>
                        <div className="badgeClauseProgress"><progress id="mix2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="mix3Status">Not started</div>
                        <div className="badgeClauseStart" id="mix3Start"></div>
                        <div className="badgeClauseEnd" id="mix3End"></div>
                        <div className="badgeClauseProgress"><progress id="mix3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="mix4Status">Not started</div>
                        <div className="badgeClauseStart" id="mix4Start"></div>
                        <div className="badgeClauseEnd" id="mix4End"></div>
                        <div className="badgeClauseProgress"><progress id="mix4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="mix5Status">Not started</div>
                        <div className="badgeClauseStart" id="mix5Start"></div>
                        <div className="badgeClauseEnd" id="mix5End"></div>
                        <div className="badgeClauseProgress"><progress id="mix5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="mix6Status">Not started</div>
                        <div className="badgeClauseStart" id="mix6Start"></div>
                        <div className="badgeClauseEnd" id="mix6End"></div>
                        <div className="badgeClauseProgress"><progress id="mix6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="mixProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Barista</div>
                        <div>
                            <progress id="baristaProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">7 different clauses<br/><Link to="/barista">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="barista1Status">Not started</div>
                        <div className="badgeClauseStart" id="barista1Start"></div>
                        <div className="badgeClauseEnd" id="barista1End"></div>
                        <div className="badgeClauseProgress"><progress id="barista1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="barista2Status">Not started</div>
                        <div className="badgeClauseStart" id="barista2Start"></div>
                        <div className="badgeClauseEnd" id="barista2End"></div>
                        <div className="badgeClauseProgress"><progress id="barista2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="barista3Status">Not started</div>
                        <div className="badgeClauseStart" id="barista3Start"></div>
                        <div className="badgeClauseEnd" id="barista3End"></div>
                        <div className="badgeClauseProgress"><progress id="barista3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="barista4Status">Not started</div>
                        <div className="badgeClauseStart" id="barista4Start"></div>
                        <div className="badgeClauseEnd" id="barista4End"></div>
                        <div className="badgeClauseProgress"><progress id="barista4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="barista5Status">Not started</div>
                        <div className="badgeClauseStart" id="barista5Start"></div>
                        <div className="badgeClauseEnd" id="barista5End"></div>
                        <div className="badgeClauseProgress"><progress id="barista5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="barista6Status">Not started</div>
                        <div className="badgeClauseStart" id="barista6Start"></div>
                        <div className="badgeClauseEnd" id="barista6End"></div>
                        <div className="badgeClauseProgress"><progress id="barista6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="barista7Status">Not started</div>
                        <div className="badgeClauseStart" id="barista7Start"></div>
                        <div className="badgeClauseEnd" id="barista7End"></div>
                        <div className="badgeClauseProgress"><progress id="barista7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="baristaProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Brewer</div>
                        <div>
                            <progress id="brewerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">6 different clauses<br/><Link to="/brewer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="brewer1Status">Not started</div>
                        <div className="badgeClauseStart" id="brewer1Start"></div>
                        <div className="badgeClauseEnd" id="brewer1End"></div>
                        <div className="badgeClauseProgress"><progress id="brewer1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="brewer2Status">Not started</div>
                        <div className="badgeClauseStart" id="brewer2Start"></div>
                        <div className="badgeClauseEnd" id="brewer2End"></div>
                        <div className="badgeClauseProgress"><progress id="brewer2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="brewer3Status">Not started</div>
                        <div className="badgeClauseStart" id="brewer3Start"></div>
                        <div className="badgeClauseEnd" id="brewer3End"></div>
                        <div className="badgeClauseProgress"><progress id="brewer3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="brewer4Status">Not started</div>
                        <div className="badgeClauseStart" id="brewer4Start"></div>
                        <div className="badgeClauseEnd" id="brewer4End"></div>
                        <div className="badgeClauseProgress"><progress id="brewer4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="brewer5Status">Not started</div>
                        <div className="badgeClauseStart" id="brewer5Start"></div>
                        <div className="badgeClauseEnd" id="brewer5End"></div>
                        <div className="badgeClauseProgress"><progress id="brewer5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="brewer6Status">Not started</div>
                        <div className="badgeClauseStart" id="brewer6Start"></div>
                        <div className="badgeClauseEnd" id="brewer6End"></div>
                        <div className="badgeClauseProgress"><progress id="brewer6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="brewerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Librarian</div>
                        <div>
                            <progress id="librarianProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">8 different clauses<br/><Link to="/librarian">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="librarian1Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian1Start">3/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian1End">3/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="librarian2Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian2Start">4/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian2End">4/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="librarian3Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian3Start">2/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian3End">2/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="librarian4Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian4Start">3/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian4End">3/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="librarian5Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian5Start">4/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian5End">5/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="librarian6Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian6Start">3/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian6End">3/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="librarian7Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian7Start">3/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian7End">3/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="librarian8Status">Complete</div>
                        <div className="badgeClauseStart" id="librarian8Start">3/1/2025</div>
                        <div className="badgeClauseEnd" id="librarian8End">3/1/2025</div>
                        <div className="badgeClauseProgress"><progress id="librarian8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="librarianProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstpink">Self Aware Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Goals</div>
                        <div>
                            <progress id="goalsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">6 different clauses<br/><Link to="/advancedgoals">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="goals1Status">Not started</div>
                        <div className="badgeClauseStart" id="goals1Start"></div>
                        <div className="badgeClauseEnd" id="goals1End"></div>
                        <div className="badgeClauseProgress"><progress id="goals1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="goals2Status">Not started</div>
                        <div className="badgeClauseStart" id="goals2Start"></div>
                        <div className="badgeClauseEnd" id="goals2End"></div>
                        <div className="badgeClauseProgress"><progress id="goals2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="goals3Status">Not started</div>
                        <div className="badgeClauseStart" id="goals3Start"></div>
                        <div className="badgeClauseEnd" id="goals3End"></div>
                        <div className="badgeClauseProgress"><progress id="goals3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="goals4Status">Not started</div>
                        <div className="badgeClauseStart" id="goals4Start"></div>
                        <div className="badgeClauseEnd" id="goals4End"></div>
                        <div className="badgeClauseProgress"><progress id="goals4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="goals5Status">Not started</div>
                        <div className="badgeClauseStart" id="goals5Start"></div>
                        <div className="badgeClauseEnd" id="goals5End"></div>
                        <div className="badgeClauseProgress"><progress id="goals5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="goals6Status">Not started</div>
                        <div className="badgeClauseStart" id="goals6Start"></div>
                        <div className="badgeClauseEnd" id="goals6End"></div>
                        <div className="badgeClauseProgress"><progress id="goals6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="goalsProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Challenge</div>
                        <div>
                            <progress id="challengeProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">1 Project<br/><Link to="/mychallengeb3">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="challenge1Status">Not started</div>
                        <div className="badgeClauseStart" id="challenge1Start"></div>
                        <div className="badgeClauseEnd" id="challenge1End"></div>
                        <div className="badgeClauseProgress"><progress id="challenge1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="challengeProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Origins</div>
                        <div>
                            <progress id="originsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">1 Project<br/><Link to="/myorigins">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="origins1Status">Not started</div>
                        <div className="badgeClauseStart" id="origins1Start"></div>
                        <div className="badgeClauseEnd" id="origins1End"></div>
                        <div className="badgeClauseProgress"><progress id="origins1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="originsProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Personality</div>
                        <div>
                            <progress id="personalityProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">6 Projects<br/><Link to="/mypersonality">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="personality1Status">Not started</div>
                        <div className="badgeClauseStart" id="personality1Start"></div>
                        <div className="badgeClauseEnd" id="personality1End"></div>
                        <div className="badgeClauseProgress"><progress id="personality1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="personality2Status">Not started</div>
                        <div className="badgeClauseStart" id="personality2Start"></div>
                        <div className="badgeClauseEnd" id="personality2End"></div>
                        <div className="badgeClauseProgress"><progress id="personality2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="personality3Status">Not started</div>
                        <div className="badgeClauseStart" id="personality3Start"></div>
                        <div className="badgeClauseEnd" id="personality3End"></div>
                        <div className="badgeClauseProgress"><progress id="personality3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 4</div>
                        <div className="badgeClauseStatus" id="personality4Status">Not started</div>
                        <div className="badgeClauseStart" id="personality4Start"></div>
                        <div className="badgeClauseEnd" id="personality4End"></div>
                        <div className="badgeClauseProgress"><progress id="personality4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 5</div>
                        <div className="badgeClauseStatus" id="personality5Status">Not started</div>
                        <div className="badgeClauseStart" id="personality5Start"></div>
                        <div className="badgeClauseEnd" id="personality5End"></div>
                        <div className="badgeClauseProgress"><progress id="personality5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 6</div>
                        <div className="badgeClauseStatus" id="personality6Status">Not started</div>
                        <div className="badgeClauseStart" id="personality6Start"></div>
                        <div className="badgeClauseEnd" id="personality6End"></div>
                        <div className="badgeClauseProgress"><progress id="personality6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="personalityProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstgreen">Wellness Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Apothecary</div>
                        <div>
                            <progress id="apothecaryProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">7 clauses<br/><Link to="/advancedapothecary">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="apothecary1Status">Not started</div>
                        <div className="badgeClauseStart" id="apothecary1Start"></div>
                        <div className="badgeClauseEnd" id="apothecary1End"></div>
                        <div className="badgeClauseProgress"><progress id="apothecary1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="apothecary2Status">Not started</div>
                        <div className="badgeClauseStart" id="apothecary2Start"></div>
                        <div className="badgeClauseEnd" id="apothecary2End"></div>
                        <div className="badgeClauseProgress"><progress id="apothecary2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="apothecary3Status">Not started</div>
                        <div className="badgeClauseStart" id="apothecary3Start"></div>
                        <div className="badgeClauseEnd" id="apothecary3End"></div>
                        <div className="badgeClauseProgress"><progress id="apothecary3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="apothecary4Status">Not started</div>
                        <div className="badgeClauseStart" id="apothecary4Start"></div>
                        <div className="badgeClauseEnd" id="apothecary4End"></div>
                        <div className="badgeClauseProgress"><progress id="apothecary4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="apothecary5Status">Not started</div>
                        <div className="badgeClauseStart" id="apothecary5Start"></div>
                        <div className="badgeClauseEnd" id="apothecary5End"></div>
                        <div className="badgeClauseProgress"><progress id="apothecary5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="apothecary6Status">Not started</div>
                        <div className="badgeClauseStart" id="apothecary6Start"></div>
                        <div className="badgeClauseEnd" id="apothecary6End"></div>
                        <div className="badgeClauseProgress"><progress id="apothecary6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="apothecary7Status">Not started</div>
                        <div className="badgeClauseStart" id="apothecary7Start"></div>
                        <div className="badgeClauseEnd" id="apothecary7End"></div>
                        <div className="badgeClauseProgress"><progress id="apothecary7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="apothecaryProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Brain Games</div>
                        <div>
                            <progress id="brainProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">7 clauses<br/><Link to="/advancedbraingames">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="brain1Status">Not started</div>
                        <div className="badgeClauseStart" id="brain1Start"></div>
                        <div className="badgeClauseEnd" id="brain1End"></div>
                        <div className="badgeClauseProgress"><progress id="brain1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="brain2Status">Not started</div>
                        <div className="badgeClauseStart" id="brain2Start"></div>
                        <div className="badgeClauseEnd" id="brain2End"></div>
                        <div className="badgeClauseProgress"><progress id="brain2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="brain3Status">Not started</div>
                        <div className="badgeClauseStart" id="brain3Start"></div>
                        <div className="badgeClauseEnd" id="brain3End"></div>
                        <div className="badgeClauseProgress"><progress id="brain3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="brain4Status">Not started</div>
                        <div className="badgeClauseStart" id="brain4Start"></div>
                        <div className="badgeClauseEnd" id="brain4End"></div>
                        <div className="badgeClauseProgress"><progress id="brain4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="brain5Status">Not started</div>
                        <div className="badgeClauseStart" id="brain5Start"></div>
                        <div className="badgeClauseEnd" id="brain5End"></div>
                        <div className="badgeClauseProgress"><progress id="brain5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="brain6Status">Not started</div>
                        <div className="badgeClauseStart" id="brain6Start"></div>
                        <div className="badgeClauseEnd" id="brain6End"></div>
                        <div className="badgeClauseProgress"><progress id="brain6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="brain7Status">Not started</div>
                        <div className="badgeClauseStart" id="brain7Start"></div>
                        <div className="badgeClauseEnd" id="brain7End"></div>
                        <div className="badgeClauseProgress"><progress id="brain7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="brainProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Diarist</div>
                        <div>
                            <progress id="diaristProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">2 projects<br/><Link to="/advanceddiarist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="diarist1Status">Not started</div>
                        <div className="badgeClauseStart" id="diarist1Start"></div>
                        <div className="badgeClauseEnd" id="diarist1End"></div>
                        <div className="badgeClauseProgress"><progress id="diarist1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="diarist2Status">Not started</div>
                        <div className="badgeClauseStart" id="diarist2Start"></div>
                        <div className="badgeClauseEnd" id="diarist2End"></div>
                        <div className="badgeClauseProgress"><progress id="diarist2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="diaristProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Good Habits</div>
                        <div>
                            <progress id="habitsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">6 clauses<br/><Link to="/advancedgoodhabits">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="habits1Status">Not started</div>
                        <div className="badgeClauseStart" id="habits1Start"></div>
                        <div className="badgeClauseEnd" id="habits1End"></div>
                        <div className="badgeClauseProgress"><progress id="habits1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="habits2Status">Not started</div>
                        <div className="badgeClauseStart" id="habits2Start"></div>
                        <div className="badgeClauseEnd" id="habits2End"></div>
                        <div className="badgeClauseProgress"><progress id="habits2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="habits3Status">Not started</div>
                        <div className="badgeClauseStart" id="habits3Start"></div>
                        <div className="badgeClauseEnd" id="habits3End"></div>
                        <div className="badgeClauseProgress"><progress id="habits3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="habits4Status">Not started</div>
                        <div className="badgeClauseStart" id="habits4Start"></div>
                        <div className="badgeClauseEnd" id="habits4End"></div>
                        <div className="badgeClauseProgress"><progress id="habits4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="habits5Status">Not started</div>
                        <div className="badgeClauseStart" id="habits5Start"></div>
                        <div className="badgeClauseEnd" id="habits5End"></div>
                        <div className="badgeClauseProgress"><progress id="habits5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="habits6Status">Not started</div>
                        <div className="badgeClauseStart" id="habits6Start"></div>
                        <div className="badgeClauseEnd" id="habits6End"></div>
                        <div className="badgeClauseProgress"><progress id="habits6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="habitsProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Beautician</div>
                        <div>
                            <progress id="beautyProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">8 clauses<br/><Link to="/beautician">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="beauty1Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty1Start"></div>
                        <div className="badgeClauseEnd" id="beauty1End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="beauty2Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty2Start"></div>
                        <div className="badgeClauseEnd" id="beauty2End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="beauty3Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty3Start"></div>
                        <div className="badgeClauseEnd" id="beauty3End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="beauty4Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty4Start"></div>
                        <div className="badgeClauseEnd" id="beauty4End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="beauty5Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty5Start"></div>
                        <div className="badgeClauseEnd" id="beauty5End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="beauty6Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty6Start"></div>
                        <div className="badgeClauseEnd" id="beauty6End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="beauty7Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty7Start"></div>
                        <div className="badgeClauseEnd" id="beauty7End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="beauty8Status">Not started</div>
                        <div className="badgeClauseStart" id="beauty8Start"></div>
                        <div className="badgeClauseEnd" id="beauty8End"></div>
                        <div className="badgeClauseProgress"><progress id="beauty8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="beautyProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstbrown">Wild Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Archaeologist</div>
                        <div>
                            <progress id="archaeologistProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">8 clauses<br/><Link to="/archaeologist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="archaeologist1Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist1Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist1End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="archaeologist2Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist2Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist2End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="archaeologist3Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist3Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist3End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="archaeologist4Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist4Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist4End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="archaeologist5Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist5Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist5End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="archaeologist6Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist6Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist6End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="archaeologist7Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist7Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist7End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="archaeologist8Status">Complete</div>
                        <div className="badgeClauseStart" id="archaeologist8Start">1/1/25</div>
                        <div className="badgeClauseEnd" id="archaeologist8End">28/2/25</div>
                        <div className="badgeClauseProgress"><progress id="archaeologist8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="archaeologistProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Geographer</div>
                        <div>
                            <progress id="geographerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">7 clauses<br/><Link to="/geographer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="geographer1Status">Not started</div>
                        <div className="badgeClauseStart" id="geographer1Start"></div>
                        <div className="badgeClauseEnd" id="geographer1End"></div>
                        <div className="badgeClauseProgress"><progress id="geographer1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="geographer2Status">Not started</div>
                        <div className="badgeClauseStart" id="geographer2Start"></div>
                        <div className="badgeClauseEnd" id="geographer2End"></div>
                        <div className="badgeClauseProgress"><progress id="geographer2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="geographer3Status">Not started</div>
                        <div className="badgeClauseStart" id="geographer3Start"></div>
                        <div className="badgeClauseEnd" id="geographer3End"></div>
                        <div className="badgeClauseProgress"><progress id="geographer3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="geographer4Status">Not started</div>
                        <div className="badgeClauseStart" id="geographer4Start"></div>
                        <div className="badgeClauseEnd" id="geographer4End"></div>
                        <div className="badgeClauseProgress"><progress id="geographer4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="geographer5Status">Not started</div>
                        <div className="badgeClauseStart" id="geographer5Start"></div>
                        <div className="badgeClauseEnd" id="geographer5End"></div>
                        <div className="badgeClauseProgress"><progress id="geographer5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="geographer6Status">Not started</div>
                        <div className="badgeClauseStart" id="geographer6Start"></div>
                        <div className="badgeClauseEnd" id="geographer6End"></div>
                        <div className="badgeClauseProgress"><progress id="geographer6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="geographer7Status">Not started</div>
                        <div className="badgeClauseStart" id="geographer7Start"></div>
                        <div className="badgeClauseEnd" id="geographer7End"></div>
                        <div className="badgeClauseProgress"><progress id="geographer7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="geographerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Marine Biologist</div>
                        <div>
                            <progress id="marineProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">8 clauses<br/><Link to="/marinebiologist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="marine1Status">Not started</div>
                        <div className="badgeClauseStart" id="marine1Start"></div>
                        <div className="badgeClauseEnd" id="marine1End"></div>
                        <div className="badgeClauseProgress"><progress id="marine1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="marine2Status">Not started</div>
                        <div className="badgeClauseStart" id="marine2Start"></div>
                        <div className="badgeClauseEnd" id="marine2End"></div>
                        <div className="badgeClauseProgress"><progress id="marine2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="marine3Status">Not started</div>
                        <div className="badgeClauseStart" id="marine3Start"></div>
                        <div className="badgeClauseEnd" id="marine3End"></div>
                        <div className="badgeClauseProgress"><progress id="marine3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="marine4Status">Not started</div>
                        <div className="badgeClauseStart" id="marine4Start"></div>
                        <div className="badgeClauseEnd" id="marine4End"></div>
                        <div className="badgeClauseProgress"><progress id="marine4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="marine5Status">Not started</div>
                        <div className="badgeClauseStart" id="marine5Start"></div>
                        <div className="badgeClauseEnd" id="marine5End"></div>
                        <div className="badgeClauseProgress"><progress id="marine5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="marine6Status">Not started</div>
                        <div className="badgeClauseStart" id="marine6Start"></div>
                        <div className="badgeClauseEnd" id="marine6End"></div>
                        <div className="badgeClauseProgress"><progress id="marine6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="marine7Status">Not started</div>
                        <div className="badgeClauseStart" id="marine7Start"></div>
                        <div className="badgeClauseEnd" id="marine7End"></div>
                        <div className="badgeClauseProgress"><progress id="marine7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="marine8Status">Not started</div>
                        <div className="badgeClauseStart" id="marine8Start"></div>
                        <div className="badgeClauseEnd" id="marine8End"></div>
                        <div className="badgeClauseProgress"><progress id="marine8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 9</div>
                        <div className="badgeClauseStatus" id="marine9Status">Not started</div>
                        <div className="badgeClauseStart" id="marine9Start"></div>
                        <div className="badgeClauseEnd" id="marine9End"></div>
                        <div className="badgeClauseProgress"><progress id="marine9Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="marineProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Wild Crafter</div>
                        <div>
                            <progress id="wildcrafterProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">5 clauses<br/><Link to="/wildcrafter">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="wildcrafter1Status">Not started</div>
                        <div className="badgeClauseStart" id="wildcrafter1Start"></div>
                        <div className="badgeClauseEnd" id="wildcrafter1End"></div>
                        <div className="badgeClauseProgress"><progress id="wildcrafter1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="wildcrafter2Status">Not started</div>
                        <div className="badgeClauseStart" id="wildcrafter2Start"></div>
                        <div className="badgeClauseEnd" id="wildcrafter2End"></div>
                        <div className="badgeClauseProgress"><progress id="wildcrafter2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="wildcrafter3Status">Not started</div>
                        <div className="badgeClauseStart" id="wildcrafter3Start"></div>
                        <div className="badgeClauseEnd" id="wildcrafter3End"></div>
                        <div className="badgeClauseProgress"><progress id="wildcrafter3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="wildcrafter4Status">Not started</div>
                        <div className="badgeClauseStart" id="wildcrafter4Start"></div>
                        <div className="badgeClauseEnd" id="wildcrafter4End"></div>
                        <div className="badgeClauseProgress"><progress id="wildcrafter4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="wildcrafter5Status">Not started</div>
                        <div className="badgeClauseStart" id="wildcrafter5Start"></div>
                        <div className="badgeClauseEnd" id="wildcrafter5End"></div>
                        <div className="badgeClauseProgress"><progress id="wildcrafter5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="wildcrafterProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Zoologist</div>
                        <div>
                            <progress id="zooProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">8 clauses<br/><Link to="/zoologist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="zoo1Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo1Start"></div>
                        <div className="badgeClauseEnd" id="zoo1End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="zoo2Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo2Start"></div>
                        <div className="badgeClauseEnd" id="zoo2End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="zoo3Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo3Start"></div>
                        <div className="badgeClauseEnd" id="zoo3End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="zoo4Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo4Start"></div>
                        <div className="badgeClauseEnd" id="zoo4End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="zoo5Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo5Start"></div>
                        <div className="badgeClauseEnd" id="zoo5End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="zoo6Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo6Start"></div>
                        <div className="badgeClauseEnd" id="zoo6End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="zoo7Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo7Start"></div>
                        <div className="badgeClauseEnd" id="zoo7End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo7Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="zoo8Status">Not started</div>
                        <div className="badgeClauseStart" id="zoo8Start"></div>
                        <div className="badgeClauseEnd" id="zoo8End"></div>
                        <div className="badgeClauseProgress"><progress id="zoo8Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="zooProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            
        </div>
    )
}

function listenFunc(element)
{
    var elementIntro = element.parentElement.querySelector(".badgeItemIntro");
    var elementClause = element.parentElement.querySelectorAll(".badgeClause");
    var elementProgress = element.parentElement.querySelector(".badgeProgress");

    if(element.getAttribute("aria-expanded") === "false")
    {
        element.setAttribute("aria-expanded", "true");
    }
    else
    {
        element.setAttribute("aria-expanded", "false");
    }

    if(elementIntro.classList.contains("hidden"))
    {
        elementIntro.classList.remove("hidden");
        elementIntro.classList.add("shown");
    } else {
        elementIntro.classList.remove("shown");
        elementIntro.classList.add("hidden");
    }

    elementClause.forEach((ec) => {
        if(ec.classList.contains("hidden"))
        {
            ec.classList.remove("hidden");
            ec.classList.add("shown");
        } else {
            ec.classList.remove("shown");
            ec.classList.add("hidden");
        }
    })
    

    if(elementProgress.classList.contains("hidden"))
    {
        elementProgress.classList.remove("hidden");
        elementProgress.classList.add("shown");
    } else {
        elementProgress.classList.remove("shown");
        elementProgress.classList.add("hidden");
    }
}

function addListener(element)
{
    element.addEventListener("click",function(){
        listenFunc(element);
    })

    element.addEventListener("keydown", function(e){
    if (e.key !== undefined){
        if(e.key === "Enter" || e.key === " " || e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight")
        {
            listenFunc(element);
        }
    } else if (e.which !== undefined) {
        if(e.key === 13 || e.key === 32 || e.key === 40 || e.key === 38 || e.key ===37 || e.key === 39)
        {
            listenFunc(element);
        }
    }
    })
}

export default Book3