import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Bangsian from '../images/March25-Bangsian.jpg';
import Banned from '../images/March25-Banned.jpg';
import Ducks from '../images/March25-Ducks.jpg';
import Film from '../images/March25-Film.jpg';
import Mushroom from '../images/March25-Mushroom.jpg';
import Ducks1 from '../images/March25-Ducks1.jpg';
import Dragon from '../images/March25-Dragon.jpg';

const  March25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"World Book Day"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle lightblue">Read a book which was made into a film, then watch the film and compare</div>
                <div className="badgePageItemContent">
                    <p>For this clause I read "Red, White and Royal Blue" by Casey McQuiston, it's one of my favourite books and definitely one of my favourite comfort films!</p>
                    <img src={Film} alt="Cover of the film aaptation version of the book Red, White and Royal Blue by Casey McQuiston" className="imgThird" width="200" height="400" />
                    <br/><br/>
                    <p>The core story of the book is maintained in the movie, but there are some changes that were made:</p>
                    <br/>
                    <p><strong>Similarities</strong></p>
                    <ul className="list">
                        <li><strong>Core plot: </strong>Both the book and the movie follow the romance between Alex Claremont-Diaz, the First Son of the United States, and Prince Henry of Britain. Their relationship begins with animosity, evolves into friendship, and blossoms into love.</li>
                        <li><strong>Themes: </strong>Both versions explore themes of identity, love, and the pressures of public life, while also addressing LGBTQ+ representation and acceptance.</li>
                        <li><strong>Key Moments: </strong>Iconic scenes, such as the cake incident that sparks their feud and the heartfelt email exchanges, are present in both.</li>
                    </ul>
                    <br/><br/>
                    <p><strong>Differences</strong></p>
                    <ul className="list">
                        <li><strong>Character Changes</strong>
                            <ul>
                                <li>In the book, Alex has an older sister, June, who plays a significant role in his life. However, June is omitted from the movie, and some of her traits and storylines are merged into the character of Nora.</li>
                                <li>Rafael Luna, a mentor figure in the book, is absent from the movie.</li>
                                <li>The movie introduces a new character, Miguel, a journalist who doesn't exist in the book.</li>
                            </ul>
                        </li>
                        <li><strong>Plot Adjustments</strong>
                            <ul>
                                <li>The book delves deeply into Alex's political ambitions and his mother's re-election campaign, providing a richer political backdrop. The movie simplifies these elements to focus more on the romance.</li>
                                <li>Some subplots, like Alex's journey of self-discovery and his close-knit trio with June and Nora, are either condensed or omitted.</li>
                            </ul>
                        </li>
                        <li><strong>Tone and Depth</strong>
                            <ul>
                                <li>The book offers more nuanced character development and explores the protagonists' internal struggles in greater depth. The movie, constrained by runtime, opts for a lighter and more streamlined narrative.</li>
                            </ul>
                        </li>
                        <li><strong>Ending</strong>
                            <ul>
                                <li>While the book provides a detailed resolution to both the romantic and political storylines, the movie wraps up more succinctly, focusing primarily on the romantic conclusion.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lightblue">Read a book from a genre you wouldn't normally choose</div>
                <div className="badgePageItemContent">
                    <p>For this clause I read a "Bangsian Fantasy". This is a genre that explores life after death, and features both historical and mythological figures, who interact with each other. The genre is named after author John Kendrick Bangs who is an American author of the late 19th and early 20th century.</p>
                    <br/>
                    <p>The key characteristics of these stories are:</p>
                    <ul className="list">
                        <li><strong>Setting: </strong>Stories often take place in an imagined afterlife, which could resemble heaven, hell, or other metaphysical realms.</li>
                        <li><strong>Characters: </strong>The cast usually includes deceased historical, literary, or mythical figures who engage in clever, ironic, or comedic interactions.</li>
                        <li><strong>Themes: </strong>These works often tackle philosophical questions about morality, existence, and legacy, but with a lighthearted tone.</li>
                    </ul>
                    <br/>
                    <p>I read the book "A House-Boat on the Styx" by John Kendrick Bangs. This story involves figures such as Shakespeare, Napolean and Socrates who gather on a house-boat in the afterlife to discuss their lives and misadventures.</p>
                    <br/>
                    <p>I found the book entertaining and it was a quick, easy read!</p>
                    <br/>
                    <img src={Bangsian} alt="The cover of the book A House-Boat on the Styx by John Kendrick Bangs" width="250" height="400" className="imgThird" />
                    <br/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lightblue">Read a book with a mushroom on the cover</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I read "Witch of Wild Things" by Raquel Vasquez Gilliland. I really enjoyed this book, the characters, story and style really appealed and I read it in one sitting!</p>
                    <br/>
                    <img src={Mushroom} alt="Cover of the book With of Wild Things by Raquel Vasquez Gilliland" className="imgThird" width="250" height="400" />
                    <br/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lightblue">Read a book which features a dragon</div>
                <div className="badgePageItemContent">
                    <p>I did order the book "Seraphine" by Rachel Hartman for this clause, but it got lost somewhere in delivery, so instead I downloaded "The Baby Dragon Cafe" by A. T. Qureshi.</p>
                    <img className="imgThird" width="400" height="400" src={Dragon} alt="Cover of the Book The Baby Dragon Cafe by A. T. Qureshi" />
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lightblue">Read a book with the word "Duck" in the title</div>
                <div className="badgePageItemContent">
                    <p>For this clause I bought"Ducks, Newburyport" by Lucy Ellmann.</p>
                    <br/>
                    <img src={Ducks} alt="Cover of the book Ducks, Newburyport by Lucy Ellman" className="imgThird" width="200" height="400" />
                    <br/><br/>
                    <p>I was not prepared for how thick this book was!! It is written in a continuous, stream of consciousness style, which is not something I have experience before.</p>
                    <br/><br/>
                    <p>Unfortunately, I had to admit defeat as there was no way I could read this before the end of the month, but I will persist!</p>
                    <p>As an alternative, I downloaded "The Duck Footed Hound" by Jim Kjelgaard, which was a quicker read!</p>
                    <img src={Ducks1} alt="Cover of the book The Duck Footed Hound by Jim Kjelgaard" width="400" height="400" className="imgThird"/>
                    <br/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lightblue">Read a banned book and unpick why it was banned</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I read the book "Brave New World" by Aldous Huxley.</p>
                    <br/>
                    <img src={Banned} alt="Cover of the book Brave New World by Aldous Huxley" className="imgThird" width="400" height="400"/>
                    <br/>
                    <p>This is a dystopian novel, set in a futuristic society where everything is highly controlled and where individual freedom and emotions are sacrified for stability and conformity of the whole.</p>
                    <br/>
                    <p>It involves genetically engineered humans, conditioned from the beginning to belong to a specific social class. People are controlle by the government using technological control, an drug for suppressing emotions and the promotion of consumerism and superficial pleasures.</p>
                    <br/>
                    <p>There are areas in the new world that haven't been transformed, called "The Wilds", where "savages" live. The savages are people as we would know them now. When a "savage" enters the new world, his experiences question individuality, freedom and what it means to be human. It also follows the story of new world humans who have their own questions about the artificial civilisation.</p>
                    <br/>
                    <p>It is a fascinating story, one which it is maybe difficult to "enjoy" as you would other works of fiction, but one that will stay with me as it critiques the dangers of sacrificing humanity for the illusion of utopia, allbeit in an extreme way.</p>
                    <br/><br/>
                    <p><strong>The banning of the book</strong></p>
                    <p>This book has been banned in the following places:</p>
                    <br/>
                    <ul className="list">
                        <li><strong>Ireland - </strong> banned in 1932 as it was deemed to be anti-religion, anti-family and blasphemous.</li>
                        <li><strong>Australia - </strong> Censored in 1932 also for being anti-religion, anti-family an blasphemous.</li>
                        <li><strong>USA - </strong> banned in various states, including: Maryland, Missouri, Oklahoma, California, Alabama, Texas, Indiana, and Delaware. Reasons include obscenity, vulgarity and inappropriateness for children.</li>
                    </ul> 
                    <br/><br/>
                    <p>Other themes objected to are:</p> 
                    <ul className="list">
                        <li>Drug use - the use of the drug called "soma" to control emotions</li>
                        <li>Sexual promiscuity - the story pushes the narrative that "everyone belongs to everyone" and does not support monogamy, rather vilifying those who do not follow a promiscuous lifestyle</li>
                        <li>Atheistic society - the story does not support religion, the word "God" is routinely replaced with the name "Ford", the name of the over-seer of the civilisation. Religion is not permitted and historical texts are banned.</li>
                        <li>Native Americans - the story has had some criticism regarding its portrayal of Native Americans who are the "savages" in this scenario.</li>
                        <li>Ethical citizenship and morality - there has been some criticism around the ethics and morality of the story in the following areas:
                            <ul>
                            <li><strong>Loss of Individual Morality: </strong> The story depicts a world where personal moral choices are replaced by state-imposed norms. Critics argue that this raises questions about the role of individual conscience in ethical decision-making.</li>
                            <li><strong>State-Controlled Ethics: </strong>The society in the book enforces a rigid moral code designed to maintain stability, often at the expense of personal freedom. This has been criticised as a warning against the dangers of authoritarian control over moral values.</li>
                            <li><strong>Consumerism and Superficiality: </strong>The emphasis on consumerism and instant gratification in the story is seen as a critique of modern societal values, questioning whether such a lifestyle undermines deeper ethical and moral considerations.</li>
                            <li><strong>Dehumanisation: </strong>The genetic engineering and conditioning of individuals to fit predetermined roles challenge the concept of human dignity and ethical treatment of individuals.</li>
                            <li><strong>Critique of Utopian Ieals: </strong>The book questions whether a utopian society, achieved through the suppression of individuality and moral complexity, is truly desirable or ethical.</li>
                            </ul>
                        </li>
                    </ul>  
                    <br/><br/>
                    <p>In relation to modern society, the ethical dilemmas in the story highlight many of the issues that we currently struggle with.</p>
                    <br/>
                    <ul className="list">
                        <li><strong>Consumerism and Instant Gratification: </strong>Modern society's focus on materialism and instant rewards mirrors the novel's portrayal of a culture obsessed with consumption and pleasure. Social media, fast fashion, and constant advertising promote a cycle of immediate satisfaction, often at the cost of long-term well-being or sustainability.</li>
                        <li><strong>Technological Control: </strong>Advances in technology, such as surveillance, genetic engineering, and artificial intelligence, raise ethical concerns about privacy, autonomy, and the potential for misuse. The novel's depiction of humans being conditioned and controlled by technology serves as a cautionary tale.</li>
                        <li><strong>Erosion of Individuality: </strong>: In our interconnected world, there's a tension between conforming to societal norms and preserving individual identity. Issues like cancel culture, groupthink, and social pressures echo the struggle for individuality in Huxley's dystopia.</li>
                        <li><strong>Mental Health and Escapism: </strong>The reliance on "soma" to escape negative emotions in the novel reflects contemporary challenges with mental health. Many people today turn to substances, entertainment, or other forms of distraction to avoid discomfort or stress.</li>
                        <li><strong>Ethics of Genetic Engineering: </strong>With developments in CRISPR and other biotechnologies, the ethical implications of designing human traits are no longer purely fictional. The novel's portrayal of a genetically stratified society warns of potential inequalities and moral dilemmas in this area.</li>
                        <li><strong>Loss of Depth in Relationships: </strong>The novel critiques shallow relationships and the absence of deep human connections, which parallels concerns today about the impact of digital communication on interpersonal relationships.</li>
                    </ul>
                    <br/><br/>
                    <p>I don't personally support the ban of the book. It is thought-provoking and an interesting narrative on what human life could become. Preventing thought on the potential changes of the future is dangerous, it's more important to consider what could be and think about it critically and potentially prevent situations that don't work for the positive advancement of humanity.</p>
                    <br/>
                </div>
            </div>
        </div>
    )
}

export default March25