import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Archaeologist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Archaeologist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemInfo brown-light">Watch</div>
                <div className="badgePageItemInfo hidden">Watch a documentary or read a book about a significant archaeological discovery</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I watched Cave of Bones of Netflix.</p>
                    <br/>
                    <p>This was a groundbreaking discovery which revealed fossils of an ancient human relative - Homo Naledi - which gave huge insights into early human behaviour.</p>
                    <p>The discovery prompted debates and research into the cognitive abilities and cultural practices of early human relatives.</p>
                    <br/>
                    <p>The significance of this discovery includes:</p>
                    <ol className="list">
                        <li><strong>New Species Identification:</strong> The fossils, discovered in 2013 and 2014, represented a previously unknown species, Homo naledi. This species had a mix of primitive and modern traits, challenging traditional views of human evolution.
                        <li><strong>Evidence of Burial Practices:</strong> Researchers found what appeared to be intentional burials, suggesting that Homo naledi may have deliberately placed their dead in the cave. This behavior was previously thought to be exclusive to larger-brained species like Homo sapiens and Neanderthals.</li>
                        <li><strong>Rock Engravings:</strong> The site also contained engravings near the burial areas, hinting at symbolic or cultural behavior, which is extraordinary for a species with a brain size much smaller than modern humans.</li>
                        <li><strong>Challenging Assumptions:</strong> The findings suggest that complex behaviors, such as burial and symbolic thought, may have emerged earlier in human evolution than previously believed.</li>
                        </li>
                    </ol>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemInfo brown-light">Timeline</div>
                <div className="badgePageItemInfo hidden">Make a timeline of major archaeological discoveries. Map them around the world</div>
                <div className="badgePageItemContent">
                    <p><strong>Timeline of major archaeological discoveries that have provided valuable insights into ancient civilisations, cultures and ways of life.</strong></p>
                    <br/>
                    <table>
                        <thead>
                            <tr>
                                <th>Discovery</th>
                                <th>Date</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Rosetta Stone</td>
                                <td>1799</td>
                                <td>Egypt</td>
                            </tr>
                            <tr>
                                <td>Pompeii</td>
                                <td>1748</td>
                                <td>Italy</td>
                            </tr>
                            <tr>
                                <td>Tutankhamun's Tomb</td>
                                <td>1922</td>
                                <td>Egypt</td>
                            </tr>
                            <tr>
                                <td>Dead Sea Scrolls</td>
                                <td>1947</td>
                                <td>Israel</td>
                            </tr>
                            <tr>
                                <td>Terracotta Army</td>
                                <td>1974</td>
                                <td>China</td>
                            </tr>
                            <tr>
                                <td>Machu Picchu</td>
                                <td>1911</td>
                                <td>Peru</td>
                            </tr>
                            <tr>
                                <td>Lascaux Cave Paintings</td>
                                <td>1940</td>
                                <td>France</td>
                            </tr>
                            <tr>
                                <td>Olduvai Gorge Fossils</td>
                                <td>1930s</td>
                                <td>Tanzania</td>
                            </tr>
                            <tr>
                                <td>Gobeki Tepe</td>
                                <td>1990s</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Stonehenge Excavations</td>
                                <td>Ongoing since 17th Century</td>
                                <td>England</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/><br/>
                    <p>Here is my map of the discoveries:</p>
                    <iframe title="Archaeological discoveries" src="https://www.google.com/maps/d/embed?mid=19gMHoCA4c6kRwh0W3krg5Lu3pLln7Fs&ehbc=2E312F" width="640" height="480"></iframe>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemInfo brown-light">Local</div>
                <div className="badgePageItemInfo hidden">Find out about digs in your local area and what has been discovered near where you live. Where possible attend an open day </div>
                <div className="badgePageItemContent">
                    <p>It hasn't been possible to attend an open day, but here are details of some digs and discoveries in my local area:</p>
                    <br/>
                    <p><strong>Discoveries</strong></p>
                    <ol className="list">
                        <li><strong>Roman Pottery Production Site at Arne:</strong> Excavations near Poole Harbour uncovered a Roman pottery production site, including over 17,000 fragments of pottery, two kilns, and building remains. The site was known for producing Black Burnished ware, a type of pottery widely traded across Roman Britain.</li>
                        <li><strong>Bronze Age Wooden Spade at Arne:</strong> A rare, nearly complete wooden spade dating back around 3,500 years was discovered during excavations at The Moors in Arne. This Middle Bronze Age tool, made from oak, provides insights into prehistoric life and craftsmanship.</li>
                        <li><strong>Maiden Castle:</strong> This iconic Iron Age hillfort near Dorchester has revealed evidence of ancient settlements, including Roman and pre-Roman artifacts. It remains one of the largest and most significant hillforts in Europe.</li>
                        <li><strong>Sandsfoot Castle in Weymouth:</strong> Archaeological work at this Tudor coastal fort has uncovered details about its construction and use during the reign of Henry VIII.</li>
                        <li><strong>Winterborne Kingston Burial Sites:</strong> Archaeologists from Bournemouth University uncovered Iron Age burial sites at Winterborne Kingston. These graves revealed a mix of traditional Iron Age and Roman burial practices, showcasing how local Britons adapted to Roman influence. Artifacts like Roman-style wine cups and flagons were found alongside traditional burial customs.</li>
                        <li><strong>Roman Settlement near Poole Harbour:</strong> Excavations at "The Moors at Arne" uncovered evidence of a significant Roman settlement, including over 17,000 pottery fragments, two kilns, and Roman building remains. This site was a hub for producing Black Burnished ware, a type of pottery highly valued in Roman Britain.</li>
                        <li><strong>Dog Burials in Iron Age Graves:</strong> At Winterborne Kingston, archaeologists also discovered dog burials, which were significant in Iron Age society. These findings provide insights into the cultural and symbolic roles of animals during that period.</li>
                    </ol>
                </div>
                <br/>
                <p><strong>Active / ongoing digs and research</strong></p>
                <ol className="list">
                    <li><strong>Winterborne Kingston Excavations:</strong> The University of Bournemouth conducts annual summer excavations at the Iron Age Durotrigian settlement site near Winterborne Kingston. Volunteers often participate in uncovering this fascinating site.</li>
                    <li><strong>Bestwall Quarry:</strong> This multi-period site near Wareham has revealed artifacts from the Mesolithic, Bronze Age, and Roman periods. It continues to be a focus of archaeological interest.</li>
                    <li><strong>Mount Pleasant Mega-Henge:</strong> Located near Dorchester, this Neolithic site features a massive enclosure and henge. Excavations have uncovered evidence of timber and stone structures.</li>
                    <li><strong>Cerne Abbas Giant:</strong>While not an active dig, ongoing research and analysis aim to uncover the origins and purpose of this iconic chalk figure.</li>
                </ol>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemInfo brown-light">Burials</div>
                <div className="badgePageItemInfo hidden">Look at burial rituals and how they differ geographically and across time.
                Research how archaeologists can recognise that a grave has been previously disturbed by grave robbers.</div>
                <div className="badgePageItemContent">
                    <p><strong>Burial rituals</strong></p>
                    <p>Burial rituals are deeply influenced by cultural, religious, and historical contexts, and they vary widely across regions and eras. Here are some examples:</p>
                    <br/>
                    <ol className="list">
                        <li><strong>Tibetan Sky Burials:</strong> Practiced in Tibet, bodies are left exposed on mountaintops to be consumed by vultures. This reflects Buddhist beliefs in the impermanence of life and the cycle of rebirth.</li>
                        <li><strong>Ghanaian Fantasy Coffins:</strong> In Ghana, coffins are often crafted to reflect the deceased's personality or profession, such as fish-shaped coffins for fishermen or airplane-shaped ones for pilots.</li>
                        <li><strong>Japanese Buddhist Funerals:</strong> These ceremonies involve chanting sutras, offering incense, and cremation. The ashes are often interred in family graves, reflecting a strong connection to ancestry.</li>
                        <li><strong>Mexican Day of the Dead:</strong> While not strictly a burial ritual, this celebration honors deceased loved ones with vibrant altars (ofrendas), marigolds, and offerings, blending indigenous and Catholic traditions.</li>
                        <li><strong>Aboriginal Tree Burials (Australia):</strong> Some Aboriginal groups historically placed the deceased in trees. The body was left to decompose naturally before the bones were collected for ceremonial burial.</li>
                        <li><strong>Bamboo Coffins (Southeast Asia):</strong> In remote areas of Southeast Asia, some communities use woven bamboo coffins for burial, reflecting sustainable and locally available practices.</li>
                        <li><strong>Toraja Hanging Graves (Indonesia):</strong> In the Toraja region of Sulawesi, Indonesia, coffins are often placed in caves or hung on cliffs. Small wooden effigies called "tau-tau" are carved to represent the deceased.</li>
                        <li><strong>Water Burials (Mekong River, Southeast Asia):</strong> Some communities along the Mekong River have traditionally conducted water burials, releasing the deceased into the river as part of spiritual beliefs connected to nature.</li>
                        <li><strong>Cave Burials (Various Locations):</strong> Some ancient and modern societies have used caves as natural crypts. For example, cave burials have been discovered in Papua New Guinea and parts of Europe.</li>
                        <li><strong>Clay Jar Burials (Philippines):</strong> In parts of the Philippines, prehistoric jar burials involved placing the remains of the deceased in large clay jars, symbolizing a spiritual journey.</li>
                    </ol>
                </div>
                <br/>
                <p><strong>Evolution across time</strong></p>
                <ol className="list">
                    <li><strong>Ancient Egypt:</strong> Elaborate mummification and tomb construction, such as the pyramids, were designed to ensure safe passage to the afterlife.</li>
                    <li><strong>Viking Burials:</strong> Vikings often buried their dead in ships, accompanied by weapons, tools, and sometimes animals, reflecting their belief in an afterlife.</li>
                    <li><strong>Medieval Europe:</strong> Christian burials became dominant, with bodies interred in churchyards. Practices like embalming were reserved for the elite.</li>
                    <li><strong>Modern Green Burials:</strong> In recent years, eco-friendly burials have gained popularity, emphasizing biodegradable materials and minimal environmental impact.</li>
                </ol>
                <br/>
                <p>More detail across specific time periods:</p>
                <br/>
                <p><strong>Prehistoric Period</strong></p>
                <ol className="list">
                    <li><strong>Middle Paleolithic Era (300,000-30,000 years ago):</strong> The earliest burials were simple, with bodies placed in shallow graves. Sites like Shanidar Cave in Iraq suggest that Neanderthals buried their dead with flowers, indicating early symbolic behavior.</li>
                    <li><strong>Upper Paleolithic Era (50,000-12,000 years ago):</strong> Burials became more elaborate, with grave goods such as tools, ornaments, and animal bones. This suggests a belief in an afterlife or spiritual connection.</li>
                </ol>
                <br/>
                <p><strong>Ancient Civilizations</strong></p>
                <ol className="list">
                    <li><strong>Ancient Egypt:</strong> Mummification and elaborate tombs, such as the pyramids, were designed to preserve the body and ensure safe passage to the afterlife. Rituals included offerings and inscriptions to guide the deceased.</li>
                    <li><strong>Mesopotamia:</strong> Burials were often accompanied by grave goods and offerings to appease the gods. The dead were believed to enter a shadowy underworld.</li>
                    <li><strong>Ancient Greece and Rome:</strong> Cremation and inhumation were common. Funerals included processions, eulogies, and offerings to honor the deceased and secure their place in the afterlife.</li>
                </ol>
                <br/>
                <p><strong>Medieval Period</strong></p>
                <ol className="list">
                    <li><strong>Christian Europe:</strong> Burial in consecrated ground became standard, reflecting religious beliefs about resurrection and the afterlife. Elaborate tombs and effigies were reserved for the elite.</li>
                    <li><strong>Islamic Traditions:</strong> Simple burials with the body wrapped in a shroud and oriented toward Mecca became the norm, emphasizing humility and equality in death.</li>
                </ol>
                <br/>
                <p><strong>Victorian Era</strong></p>
                <ol className="list">
                    <li>Mourning rituals became highly elaborate, with strict codes of behavior and attire. Black clothing, memorial photography, and ornate tombstones were common. This period also saw the rise of embalming and funeral homes.</li>
                </ol>
                <br/>
                <p><strong>Modern Era</strong></p>
                <ol className="list">
                    <li><strong>Personalization:</strong> Funerals now often reflect the personality and interests of the deceased, with multimedia presentations, unique ceremonies, and celebrations of life.</li>
                    <li><strong>Green Burials:</strong> Eco-friendly practices, such as biodegradable caskets and natural burials, have gained popularity as people seek sustainable alternatives.</li>
                    <li><strong>Technological Integration:</strong> Live-streamed funerals, online memorials, and digital guestbooks have become common, allowing global participation.</li>
                </ol>
                <br/><br/>
                <p><strong>Grave robbers and burial site disruption</strong></p>
                <p>These are the signs that archaeologists use to determine whether a grave has been disturbed by grave robbers:</p>
                <br/>
                <ol className="list">
                    <li><strong>Disordered Skeletons:</strong> Bones may be displaced, incomplete, or scattered within the grave. This contrasts with the careful arrangement typical of undisturbed burials.</li>
                    <li><strong>Missing Grave Goods:</strong> Items like jewelry, weapons, or pottery that are expected based on cultural practices may be absent. Sometimes, only fragments or traces (e.g., metal stains on bones) remain.</li>
                    <li><strong>Disturbed Soil Layers:</strong> The stratigraphy of the grave may show signs of disruption, such as uneven or mixed soil layers, indicating that the grave was reopened.</li>
                    <li><strong>Tool Marks:</strong> Evidence of digging tools, such as scratches or gouges on coffins, stones, or the grave walls, can suggest unauthorized access.</li>
                    <li><strong>Secondary Fill:</strong> The soil used to refill the grave after disturbance may differ in texture or composition from the original fill.</li>
                    <li><strong>Hasty Reburial:</strong> If robbers attempted to conceal their actions, the grave might appear poorly resealed, with uneven surfaces or gaps.</li>
                    <li><strong>Artifacts Left Behind:</strong> Occasionally, robbers leave behind broken or discarded items, providing clues about their activities.</li>
                </ol>
                <br/>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemInfo brown-light">Recording</div>
                <div className="badgePageItemInfo hidden">Learn about site recording and the symbols used on drawings of sites. Make a site plan of a well known archaeological site.</div>
                <div className="badgePageItemContent">
                    <p>Site recording is a process used to document the features, artifacts and stratgraphy of a site. This means that information is preserved for analysis and interpretation after excavation. These are some of the methods used:</p>
                    <br/>
                    <p>These sites provide examples and guidance for site recordings:</p>
                    <ul className="list">
                        <li>Historic England Archaeology Recording Manual 2018: This manual offers detailed guidance on recording archaeological sites, including context sheets, stratigraphy, and matrices.<br/> https://historicengland.org.uk/content/docs/research/historic-england-archaeological-recording-manual-2018 </li>
                        <li>Universal Guidance for Archaeological Monitoring and Recording: Published by the Chartered Institute for Archaeologists, this document outlines best practices for site recording and monitoring.<br/> https://www.archaeologists.net/sites/default/files/Universal%20guidance%20for%20archaeological%20monitoring%20%26%20recording.pdf</li>
                        <li>A Guide to the Archaeological Field Recording of Historic Buildings: This guide focuses on recording historic buildings, including techniques for drawings, photography, and written accounts.<br/> https://www.explorethepast.co.uk/wp-content/uploads/2020/07/Building-recording-guidance-1.pdf </li>
                    </ul>
                    <br/><br/>
                    <p><strong>Site recording</strong></p>
                    <ol className="list">
                        <li><strong>Context sheets:</strong> Each layer or feature (e.g., a wall, pit, or burial) is assigned a unique context number and recorded on a context sheet. This includes details like soil composition, dimensions, and relationships to other contexts.</li>
                        <li><strong>Plans and Sections:</strong> Archaeologists create scaled drawings of the site, showing the horizontal layout (plans) and vertical profiles (sections). These drawings capture the spatial relationships between features.</li>
                        <li><strong>Photographic Records:</strong> Photographs are taken to visually document the site and its features at various stages of excavation.</li>
                        <li><strong>Finds Recording:</strong> Artifacts are cataloged with their context numbers, ensuring their provenance is preserved.</li>
                    </ol>
                </div>
                <br/>
                <p><strong>Symbols on drawings</strong></p>
                <p>Standardised symbols are used to represent features and materials, which helps to ensure that drawings are clear and universally understood. These are some of the common ones:</p>
                <br/>
                <ul className="list">
                    <li><strong>Dashed Lines:</strong> Indicate features that are uncertain or inferred, such as the edge of a pit.</li>
                    <li><strong>Solid Lines:</strong> Represent clearly defined features like walls or ditches.</li>
                    <li><strong>Hatching or Shading:</strong> Used to show different materials, such as stone, brick, or soil types.</li>
                    <li><strong>Crosses or Dots:</strong> Indicate postholes or stakeholes.</li>
                    <li><strong>Arrows:</strong> Show the direction of slope or flow, such as in a ditch or water channel.</li>
                    <li><strong>Numbers and Letters:</strong> Context numbers, grid references, or labels for specific features.</li>
                </ul>
                <br/>
                <br/>
                <p><strong>Site Plan</strong></p>
                todo


            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemInfo brown-light">Dating</div>
                <div className="badgePageItemInfo hidden">Explain the difference between relative and absolute dating, and give examples of each type of dating using
                artefacts, structures and sites.</div>
                <div className="badgePageItemContent">
                    <p>The age of objects, sites and materials are determined through the use of relative and absolute dating.</p>
                    <br/>
                    <p><strong>Relative dating</strong></p>
                    <p>This determines the sequence of events or objects, whether one is older or younger than another. It doesn't provide an exact age.</p>
                    <br/>
                    <p>Techniques:</p>
                    <ul className="list">
                        <li><strong>Stratigraphy:</strong> Based on the principle that layers of sediment or rock are deposited in chronological order (oldest on the bottom, youngest on top).</li>
                        <li><strong>Typology:</strong> Using styles of artifacts (e.g., pottery or tools) to place them within a relative timeline.</li>
                        <li><strong>Faunal Association:</strong> Analyzing animal fossils found alongside artifacts to infer relative ages.</li>
                    </ul>
                    <br/><br/>
                    <p><strong>Absolute dating</strong></p>
                    <p>Provides a specific age or time range for an object or material (usually in years)</p>
                    <br/>
                    <p>Techniques</p>
                    <ul className="list">
                        <li><strong>Radiocarbon Dating (C-14):</strong> Measures the decay of carbon isotopes in organic materials, effective for items up to 50,000 years old.</li>
                        <li><strong>Dendrochronology:</strong> Uses tree-ring patterns to date wooden objects with high precision.</li>
                        <li><strong>Thermoluminescence:</strong> Dates ceramics or minerals by measuring trapped electrons released when heated.</li>
                    </ul>
                    <br/><br/>
                    <p>The key differences are:</p>
                    <ul className="list">
                        <li><strong>Relative dating:</strong> focuses on sequence and comparison (older or younger).</li>
                        <li><strong>Absolute dating:</strong> provides a numerical or calendar-based age.</li>
                    </ul>
                    <br/><br/>
                    <p>Examples of how both are used to date artifcats, structures and sites:</p>
                    <br/>
                    <p><strong>Relative dating</strong></p>
                    <br/>
                    <ol className="list">
                        <li><strong>Artifacts:</strong>
                            <ul>
                                <li><strong>Pottery Typology:</strong> At archaeological sites in the Mediterranean, pottery styles (e.g., Mycenaean vs. Geometric) are used to establish a relative timeline of cultural phases.</li>
                            </ul>
                        </li>
                        <li><strong>Structures:</strong>
                            <ul>
                                <li><strong>Stratigraphy in Ancient Cities:</strong> In places like Troy, layers of occupation (e.g., Troy I to Troy IX) are identified, with deeper layers being older.</li>
                            </ul>
                        </li>
                        <li><strong>Sites:</strong>
                            <ul>
                                <li><strong>Stonehenge:</strong> Relative dating of surrounding burial mounds and artifacts helps place the construction phases of Stonehenge in a broader prehistoric timeline.</li>
                            </ul>
                        </li>
                    </ol>
                </div>
                <br/><br/>
                <ol className="list">
                    <li><strong>Artifacts:</strong>
                        <ul>
                            <li><strong>Radiocarbon Dating of Organic Materials:</strong> Charcoal found in a prehistoric hearth in Africa was dated to approximately 50,000 years ago using radiocarbon dating.</li>
                        </ul>
                    </li>
                    <li><strong>Structures:</strong> 
                        <ul>
                            <li><strong>Dendrochronology of Wooden Beams:</strong> Wooden beams from Pueblo Bonito in Chaco Canyon, USA, were dated to specific years in the 9th and 10th centuries CE.</li>
                        </ul> 
                    </li>
                    <li><strong>Sites:</strong>
                        <ul>
                            <li><strong>Thermoluminescence Dating of Ceramics:</strong> At Mohenjo-Daro in the Indus Valley, thermoluminescence dating was used to determine the age of fired clay bricks.</li>
                        </ul>
                    </li>
                </ol>
                <br/>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemInfo brown-light">Anomalies</div>
                <div className="badgePageItemInfo hidden">learn about at least five archaeological anomalies around the world and the theories which relate to them.</div>
                <div className="badgePageItemContent">
                    <p><strong>Archaeological Anomalies</strong></p>
                    <ol className="list">
                        <li><strong>The Antikythera Mechanism (Greece):</strong>
                            <ul>
                                <li><strong>Discovery:</strong> Found in a shipwreck off the coast of Greece in 1901, this 2,000-year-old device is often referred to as the world's first analog computer.</li>
                                <li><strong>Theories:</strong> It is believed to have been used to predict astronomical events and eclipses. Some theorists suggest it indicates a level of technological sophistication far ahead of its time.</li>
                            </ul>
                        </li>
                        <li><strong>The Nazca Lines (Peru):</strong>
                            <ul>
                                <li><strong>Discovery:</strong> These massive geoglyphs, depicting animals, plants, and geometric shapes, were created by the Nazca culture between 500 BCE and 500 CE.</li>
                                <li><strong>Theories:</strong> Some suggest they were ceremonial pathways or astronomical markers, while others propose they were offerings to deities. Fringe theories even speculate about extraterrestrial involvement.</li>
                            </ul>
                        </li>
                        <li><strong>Göbekli Tepe (Turkey):</strong>
                            <ul>
                                <li><strong>Discovery:</strong> This 11,000-year-old site is considered the world's oldest known temple, predating agriculture and permanent settlements.</li>
                                <li><strong>Theories:</strong> It challenges the traditional view that organized religion followed the development of agriculture. Some believe it was a gathering place for hunter-gatherers, while others see it as evidence of an advanced prehistoric society.</li>
                            </ul>
                        </li>
                        <li><strong>The Stone Spheres of Costa Rica:</strong>
                            <ul>
                                <li><strong>Discovery:</strong> Hundreds of nearly perfect stone spheres, some weighing several tons, were found in the Diquís Delta.</li>
                                <li><strong>Theories:</strong> Their purpose remains unclear. Suggestions range from astronomical tools to markers of social status or territorial boundaries.</li>
                            </ul>
                        </li>
                        <li><strong>The Baghdad Battery (Iraq):</strong>
                            <ul>
                                <li><strong>Discovery:</strong> Found near Baghdad, this 2,000-year-old artifact consists of a clay jar, a copper cylinder, and an iron rod.</li>
                                <li><strong>Theories:</strong> Some believe it was an ancient galvanic cell used for electroplating, while others argue it was a simple storage vessel.</li>
                            </ul>
                        </li>
                    </ol>
                    <br/>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 8</div>
                <div className="badgePageItemInfo brown-light">Photography</div>
                <div className="badgePageItemInfo hidden">Learn about the various uses of photography in archaeology - including artefact documentation, excavation records
                fluorescence photography, endoscopic photography and thermal infrared imagery.</div>
                <div className="badgePageItemContent">
                    <p>Photography in archaeology is vital for documentation, analysis and interpretation. The techniques help to enhance our understanding of the past and ensure the fragile artifacts and sites are preserved. These types of photography are used:</p>
                    <br/>
                    <ol className="list">
                        <li><strong>Artifact Documentation</strong>
                            <ul>
                                <li><strong>Purpose:</strong> To create detailed visual records of artifacts for study, publication, and preservation.</li>
                                <li><strong>Use:</strong> High-resolution images capture the shape, texture, and details of artifacts, aiding in analysis and comparison. Close-up and macro photography are often employed for intricate designs or inscriptions.</li>
                            </ul>
                        </li>
                        <li><strong>Excavation Records</strong>
                            <ul>
                                <li><strong>Purpose:</strong> To document the progress of excavations and the spatial relationships of features and finds.</li>
                                <li><strong>Use:</strong> Photographs are taken at various stages of excavation to record stratigraphy, site layout, and context. These images are crucial for reconstructing the site after excavation.</li>
                            </ul>
                        </li>
                        <li><strong>Fluorescence Photography</strong>
                            <ul>
                                <li><strong>Purpose:</strong> To reveal details not visible under normal lighting conditions.</li>
                                <li><strong>Use:</strong> Ultraviolet (UV) fluorescence photography highlights organic residues, pigments, or surface treatments on artifacts. It is particularly useful for studying ancient paintings or detecting repairs.</li>
                            </ul>
                        </li>
                        <li><strong>Endoscopic Photography</strong>
                            <ul>
                                <li><strong>Purpose:</strong> To explore and document inaccessible areas.</li>
                                <li><strong>Use:</strong> Endoscopic cameras are used to investigate the interiors of tombs, caves, or small artifacts without causing damage. This technique is invaluable for preserving fragile sites.</li>
                            </ul>
                        </li>
                        <li><strong>Thermal Infrared Imagery</strong>
                            <ul>
                                <li><strong>Purpose:</strong> To detect subsurface features and temperature variations.</li>
                                <li><strong>Use:</strong> Thermal imaging identifies buried structures, such as walls or foundations, by detecting heat differences in the soil. It is also used to monitor environmental conditions affecting archaeological sites.</li>
                            </ul>
                        </li>
                    </ol>
                    <br/>
                </div>
            </div>   
        </div>
    )
}

export default Archaeologist