import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Dinner from '../images/February25-Dinner.jpg';
import Fold1 from '../images/February25-BookFolding1.jpg';
import Fold2 from '../images/February25-BookFolding2.jpg';
import Fold3 from '../images/February25-BookFolding3.jpg';
import Wings from '../images/February25-AngelWings.jpg';

const  February25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Cupid Challenge"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Craft</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Make or decorate a heart using a craft which is new to you.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause, I tried book-folding which I have not done before. I couldn't bring myself to do it on an actual book though, so I made my own!!</p>
                        <p>I followed the tutorial here: https://www.diyncrafts.com/53542/repurpose/diy-heart-shaped-book-folding-video-tutorial</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Fold1} alt="Starting the process of folding book pages to a pattern" with="400" height="400" /></div>
                            <div className="galleryItem"><img src={Fold2} alt="The process of folding book pages to a pattern" with="400" height="400" /></div>
                            <div className="galleryItem"><img src={Fold3} alt="A book with pages folded into a heart shape" with="400" height="400" /></div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Gods of Love</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Research different gods linked to love in Mythology</div>
                    <div className="badgePageItemEvidence"> 
                       <p>For this clause I have been looking at the different gods linked to love in mythology from around the world. Here is what I found:</p>
                       <br/>
                       <ul className="list">
                        <li><strong>Africa</strong>
                            <ul>
                                <li><strong>Oshun (Yoruba)</strong> Goddess of love, beauty, and fertility, often associated with rivers and fresh water.</li>
                                <li><strong>Erzulie Freda (Haitian Vodou) </strong>Loa of love, beauty, and luxury, representing romantic and sensual love.</li>
                            </ul>
                        </li>
                        <li><strong>Asia</strong>
                            <ul>
                                <li><strong>Kama (Hindu) </strong>God of love and desire, often depicted with a bow made of sugarcane and arrows of flowers.</li>
                                <li><strong>Xi Shi (China) </strong>One of the Four Beauties of ancient China, associated with love and allure.</li>
                            </ul>
                        </li>
                        <li><strong>Europe</strong>
                            <ul>
                                <li><strong>Aphrodite (Greek) </strong>Goddess of love, beauty, and sexuality, born from sea foam.</li>
                                <li><strong>Freya (Norse) </strong>Goddess of love, beauty, and fertility, also associated with war and death.</li>
                            </ul>
                        </li>
                        <li><strong>North America</strong>
                            <ul>
                                <li><strong>Xochiquetzal (Aztec) </strong>Goddess of love, fertility, and beauty, often linked to flowers and pleasure.</li>
                                <li><strong>Sedna (Inuit) </strong>While primarily a sea goddess, she is sometimes connected to themes of love and relationships.</li>
                            </ul>
                        </li>
                        <li><strong>South America</strong>
                            <ul>
                                <li><strong>Chasca (Inca) </strong>Goddess of the dawn and twilight, associated with love and beauty.</li>
                                <li><strong>Mama Quilla (Inca) </strong>Goddess of the moon, linked to marriage and feminine love.</li>
                            </ul>
                        </li>
                        <li><strong>Oceania</strong>
                            <ul>
                                <li><strong>Rongo (Polynesia) </strong>God of fertility and cultivated food, often tied to love and abundance.</li>
                                <li><strong>Hina (Polynesia) </strong>Goddess of the moon and love, celebrated in various Polynesian myths.</li>
                            </ul>
                        </li>
                        <li><strong>Middle East</strong>
                            <ul>
                                <li><strong>Ishtar (Mesopotamia) </strong>Goddess of love, beauty, and war, known for her passionate and complex nature.</li>
                                <li><strong>Astarte (Canaan) </strong> Goddess of love, fertility, and sexuality, worshipped across the ancient Near East.</li>
                            </ul>
                        </li>
                       </ul> 
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Gift</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Bake or cook a heart-shaped dish</div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I customised my dinner with a heart shape!</p>
                        <img src={Dinner} alt="A heart-shaped egg on top of a base of tomato, peppers, onions and chilli in a pan" width="400" height="400" className="imgThird" />
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Read</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Read the story of Cupid and Psyche</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I read the story of Cupid and Psyche here:</p>
                        <p>https://www.thoughtco.com/cupid-and-psyche-117895</p>
                        <p>https://en.wikipedia.org/wiki/Cupid_and_Psyche</p>
                        <br/><br/>
                        <p><strong>Summary of the story of Cupid and Psyche</strong></p>
                        <p>The tale of **Cupid and Psyche** is a Roman love story about a mortal woman, Psyche, and Cupid, the god of love. Psyche's beauty angers Venus, Cupid's mother, who sends her son to make Psyche fall in love with a vile creature. Instead, Cupid falls in love with her himself. They marry, but he forbids her from seeing his face. When Psyche's curiosity leads her to break this rule, Cupid leaves her. To reunite with him, Psyche undertakes a series of near-impossible tasks set by Venus. In the end, Psyche succeeds, proving her love and earning immortality so she can be with Cupid forever. It's a tale of trust, perseverance, and the power of love.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Wings</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Customise an item of clothing or an accessory with cupid wings</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Rather than a customisation, I made accesories for this clause, although they could be sewn onto anything as a customisation. Here are my angel wing ear cuffs.</p>
                        <p>Instruction video: https://www.youtube.com/watch?v=ezsqJMDh4rQ</p>
                        <img className="imgThird" src={Wings} alt="Angel wings made from silver wire" width="400" height="400"/>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilacbright">Traditions</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Learn about different global traditions linked to love</div>
                    <div className="badgePageItemEvidence"> 
                        <p>There are many ways that love is celebrated around the world, here are some of them:</p>
                        <br/>
                        <ol className="list">
                            <li><strong>Exchange of Love Tokens</strong>
                                <ul>
                                    <li><strong>Europe: </strong>In medieval England and France, knights would present their lovers with tokens of affection, such as embroidered scarves, symbolizing devotion.</li>
                                    <li><strong>India: </strong>The practice of gifting jewelry, particularly gold, to express love and commitment is a deeply rooted tradition.</li>
                                </ul>
                            </li>
                            <li><strong>Courtship Rituals</strong>
                                <ul>
                                    <li><strong>Maasai (Kenya / Tanzania): </strong>Young men perform elaborate dances and chants to impress potential brides, showcasing their strength and vitality.</li>
                                    <li><strong>Philippines: </strong> The Harana involves serenading a woman outside her home, with heartfelt songs symbolizing earnest love.</li>
                                </ul>
                            </li>
                            <li><strong>Festivals of Love</strong>
                                <ul>
                                    <li><strong>China: </strong>The Qixi Festival, also known as the "Chinese Valentine's Day," celebrates the romantic story of the weaver girl and the cowherd.</li>
                                    <li><strong>Japan: </strong>Valentine's Day is marked by women giving chocolates to men, while men reciprocate a month later on White Day.</li>
                                </ul>
                            </li>
                            <li><strong>Symbolic Acts of Unity</strong>
                                <ul>
                                    <li><strong>Wales: </strong>The tradition of crafting intricately carved "love spoons" dates back centuries and serves as a symbol of commitment.</li>
                                    <li><strong>India: </strong>Wedding rituals often involve tying knots (as in the Mangalsutra ceremony), signifying the unbreakable bond of marriage.</li>
                                </ul>
                            </li>
                            <li><strong>Love and Nature</strong>
                                <ul>
                                    <li><strong>Japan: </strong>Lovers visit shrines dedicated to the deity of matchmaking, such as the Jishu Shrine in Kyoto, to pray for a lasting relationship.</li>
                                    <li><strong>Scotland: </strong>Drinking from the "Lover's Fountain" or visiting stone circles like the Clava Cairns is thought to bless relationships.</li>
                                </ul>
                            </li>
                            <li><strong>Superstitions and Beliefs</strong>
                                <ul>
                                    <li><strong>South Korea: </strong>Couples often celebrate monthly love anniversaries, with the 14th of each month dedicated to a specific type of love celebration.</li>
                                    <li><strong>Italy: </strong>In Verona, visitors leave love notes on the wall beneath Juliet's balcony, seeking blessings for their own love stories.</li>
                                </ul>
                            </li>
                            <li><strong>Marriage and Love Customs</strong>
                                <ul>
                                    <li><strong>Indonesia: </strong>The Toraja people hold elaborate, multiday weddings to honor love and family bonds.</li>
                                    <li><strong>Norway: </strong>Brides traditionally wear silver or gold crowns adorned with charms to ward off evil spirits and ensure a happy marriage.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default February25