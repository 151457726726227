import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Classicist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Classicist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Language</div>
                <div className="badgePageItemInfo hidden">Choose a historical language and study it for a period of at least three months</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I chose to study Old Norse. I have been studying Swedish for my Linguist badge and it is a descendant of Old Norse.</p>
                    <p>In parallel, I have also been doing some study with Icelandic, which is the closest modern language to Old Norse, and Norwegian which is also closely related.</p>
                    <br/><br/>
                    <p>For Old Norse, I found a tutorial playlist from Dr. Jackson Crawford, University of Colorado, which has been brilliant. I haven't done a session every single day, but I have completed 20 classes in three months.</p>
                    <p>Playlist: https://www.youtube.com/playlist?list=PLATNGYBQ-TjrVWv1Vh4aS3M-Twg-Ymwtf</p>
                    <br/><br/>
                    <p>For Icelandic, I use an app recommendation that I found online, "Learn_Icelandic". It was described as being like Duolino for Icelandic, but I haven't found that to be the case. It has a series of quizzes that give you words or phrases to translate - without any instruction. I have been able to complete the exercises base solely on my Swedish knowlege, which is interesting and shows how closely the languages are related!</p>
                    <br/><br/>
                    <p>I added Norwegian to my DuoLingo studies and have been doing that daily. Again, it's very similar to Swedish so has been relatively easy to pick up so far, but I do have to remember to switch between the nuances of spelling and accents!</p>
                    <br/>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Vocabulary</div>
                <div className="badgePageItemInfo hidden">Spend at least two hours a week improving your vocabulary and translation skills</div>
                <div className="badgePageItemContent">
                    <p>Whilst I haven't been able to take classes every single day, I have done more than two hours a week over three months so far, and will continue. I take the classes online, write the vocabulary into my notebook and do the translation exercises given.</p>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Read</div>
                <div className="badgePageItemInfo hidden">Read at least three non-fiction books about your chosen period of history - eg if you have chosen to study Latin
                learn about the Romans</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I have read the following books:</p>
                    <br/>
                    <ul className="list">
                        <li><strong>Laughing Shall I Die: Lives and Deaths of the Great Vikings - Tom Shippey:</strong> A unique take on Viking culture, focusing on their attitudes toward life and death.</li>
                        <li><strong>Beyond the Northlands: Viking Voyages and the Old Norse Sagas - Eleanor Rosamud Barraclough: </strong>This book combines history and literature to explore Viking voyages and their sagas.</li>
                        <li><strong>The Children of Ash and Elm: A History of the Vikings - Neil Price:</strong> This book provides a comprehensive look at Viking society, including their culture, mythology, and daily life.</li>
                    </ul>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Stories</div>
                <div className="badgePageItemInfo hidden">Read at least three stories originally written in your chosen language - eg Greek mythology</div>
                <div className="badgePageItemContent">
                    <p>During my Old Norse studies, we read extracts from stories as part of translation activities.</p>
                    <p>In addition, I have been using this link to read various stories: https://heimskringla.no/wiki/Heimskringla</p>
                    <br/><br/>
                    <p>I have also read the following texts, which are presented in dual language versions.</p>
                    <ul className="list">
                        <li>Poetic Edda - Dual Language Edition, Edward Petit. The Poetic Edda is a modern name for an untitled collection of Old Norse anonymous narritive poems in alliterative verse.</li>
                        <li>Tattúínárdǿla saga - Dual Language Edition, Dr. Jackson Crawford. This is a reimagining of Star Wars as an Icelandic saga and is part of a series of blog posts.</li>
                    </ul>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Impact</div>
                <div className="badgePageItemInfo hidden">In what ways has your chosen language and ancient civilisation affected the way we live today? Find an interesting way to present your findings</div>
                <div className="badgePageItemContent">
                    <p>Old Norse evolved from Proto-Norse and was spoken from roughly the 8th century to the 14th century during the Viking age. It was used across Scandinavia and in Norse settlements incluing Iceland, Greenaln an parts of the British Isles. It transitione into the modern North Germanic languages like Icelandic, Norwegian and Danish.</p>
                    <p>The language an Viking civilisation have left a lasting influence on our modern society, incluing the following:</p>
                    <br/>
                    <ul className="list">
                        <li><strong>Language an Vocabulary: </strong>Many English words, such as "sky," "egg," "knife," and "window," have Old Norse origins. Place names in regions like Yorkshire and the Danelaw also reflect Viking influence, with suffixes like "-by" (meaning town or village) still in use.</li>
                        <li><strong>Democratic Principles: </strong>The Viking "Thing," an assembly for decision-making, is considered a precursor to modern democratic systems. This practice of inclusive governance has influenced political structures in Scandinavia and beyond.</li>
                        <li><strong>Cultural Traditions: </strong>Festivals like Midsummer in Scandinavia have roots in Viking celebrations, showcasing their reverence for nature and seasonal changes.</li>
                        <li><strong>Mythology and Entertainment: </strong>Norse mythology, with gods like Odin and Thor, continues to inspire literature, movies, and TV shows, enriching global pop culture.</li>
                        <li><strong>Seafaring and Exploration: </strong>Viking advancements in shipbuilding and navigation laid the groundwork for future exploration, influencing maritime technology and trade routes.</li>
                        <li><strong>Art and Symbols: </strong>Viking runes and designs are often incorporated into modern art, jewelry, and tattoos, serving as cultural symbols.</li>
                        <li><strong>Genetic and Cultural Diversity: </strong>Viking interactions with various cultures during their raids and trade have contributed to genetic diversity and cultural exchanges.</li>
                    </ul>
                    <br/><br/>
                    <p>I chose to explore language, runes and celebrations a little further to produce these infographics:</p>
                    <p>TODO: Infographics.</p>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemInfo blue-light">Equivalents</div>
                <div className="badgePageItemInfo hidden">Choose an aspect of your chosen civilisation which no longer exists today. Is there a modern equivalent? If not, why not?
                What can we learn from it?</div>
                <div className="badgePageItemContent">
                    <p>There are several aspects of the Viking civilisation that haven't persisted today:</p>
                    <br/>
                    <ul className="list">
                        <li><strong>Pagan Norse Religion: </strong>The Vikings practiced a polytheistic religion centered around gods like Odin, Thor, and Freyja. With the Christianisation of Scandinavia, this belief system was largely replaced, and the old rituals and practices are no longer part of mainstream culture.</li>
                        <li><strong>Raiding as a Way of Life: </strong>Viking raids were a defining feature of their civilisation, driven by the need for resources and wealth. As European societies developed stronger defenses and the Vikings integrated into these societies, raiding ceased to be a viable or necessary practice.</li>
                        <li><strong>Longship Technology: </strong>While Viking longships were revolutionary for their time, modern advancements in shipbuilding have rendered these designs obsolete. However, they remain iconic symbols of Viking ingenuity.</li>
                        <li><strong>Clan-Based Social Structure: </strong>Viking society was organized around clans and kinship ties, which played a central role in governance and daily life. This structure has been replaced by modern political and social systems.</li>
                        <li><strong>Runic Writing: </strong>The Vikings used runes for communication and inscriptions, but this writing system has been replaced by the Latin alphabet in Scandinavia.</li>
                    </ul>
                    <br/><br/>
                    <p><strong>Runic Writing</strong></p>
                    <p>Looking more closely at Runic Writing and Symbolism, this faded over time due to changes in language, religion and cultural integration.</p>
                    <br/>
                    <ul className="list">
                        <li><strong>Adoption of the Latin Alphabet: </strong>As Christianity spread across Scandinavia during the Middle Ages, the Latin alphabet replaced runic writing. This was partly due to religious and administrative needs, as Latin became the dominant language for records and communication.</li>
                        <li><strong>Oral Tradition: </strong>Much of Viking culture was rooted in oral storytelling, and written records played a secondary role. As societies evolved, more formalised and standardised writing systems like Latin became the norm.</li>
                        <li><strong>Decline of the Viking Age: </strong>With the end of the Viking Age in the 11th century, their distinct cultural practices, including runic writing, were gradually absorbed or replaced by the customs of larger European kingdoms.</li>
                        <li><strong>Religious Suppression: </strong>Norse pagan practices, often tied to the use of runes, were suppressed as Christianity took hold. Runes were sometimes associated with "pagan magic," leading to further decline.</li>
                    </ul>
                    <br/><br/>
                    <p>The lack of a modern equivalent is due to evolving functions and cultural roles.</p>
                    <ul className="list">
                        <li><strong>Utility Superseded by Other Systems: </strong>Runes were a practical script for carving into wood, stone, or metal, designed for the Vikings' tools and materials. Modern alphabets, like the Latin script, are more versatile for printing, typing, and digital communication, making runes less practical in today's world.</li>
                        <li><strong>Integration of Cultures: </strong>As societies modernised and interconnected, runes were replaced by more universal systems of writing. The Latin alphabet became the dominant script across Europe, promoting consistent communication and record-keeping.</li>
                        <li><strong>Decline of Mystical and Ritual use: </strong>For the Vikings, runes were not just a writing system but carried symbolic and magical significance. In modern times, writing is viewed more as a tool than as a spiritual practice, and this shift in mindset leaves less room for a mystical writing system.</li>
                        <li><strong>Focus on Simplicity and Efficiency: </strong>Modern communication prioritises speed and clarity. The aesthetic and symbolic richness of runes is less compatible with the rapid pace and standardised formats of modern writing systems.</li>
                    </ul>
                    <br/><br/>
                    <p><strong>Learning from the Runic writing system</strong></p>
                    <p>Preservation of Cultural Heritage: Runes serve to remind us that it is important to document and preserve cultural traditions and can inspire the potential for keeping endangered languages alive.</p>
                    <p>Adaptability of Communication: Highlights the ability of modern societies to adapt to more efficient or more widely-used systems for communication , leading to the embracing of modern technological and liguistic evolution, without losing their roots.</p>
                    <p>Symbols an Shared Meaning: Teach the power of symbols in building identities and conveying complex ideas - important in branding, art and even emojis.</p>
                    <p>Mystique and Connection to Nature: Reminds us to explore the deeper measnings and connections behind our writing an symbolism.</p>
                    <p>Appreciation of Aesthetics: as communication becomes faster and ever more abbreviated, runes help to remind us of the beauty and artistry that can be infused into writing.</p>
                    <br/><br/>
                    <p>Although no longer in active, live use, runes and symbols continue to appear in art around the world, including in pictures, crafts and tattoos, so they are not lost completely.</p>
                    <br/><br/>
                </div>
            </div>   
        </div>
    )
}

export default Classicist