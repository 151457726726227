import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Lobsters from '../images/advancedanimallover-lobsters.png';
import Cert1 from '../images/advancedanimallover-lobsters1.jpg';
import Cert2 from '../images/advancedanimallover-lobsters2.jpg';

const AdvancedAnimalLover = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Animal Lover"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Project</div>
                <div className="badgePageItemInfo hidden">Play an active part in a project to help an animal which is endangered, either in your region or abroad</div>
                <div className="badgePageItemContent">
                <p>For this clause I have been helping the South Cost Threatened Fauna Recovery project on Zooniverse.</p>
                <p>https://www.zooniverse.org/projects/abbsta/south-coast-threatened-fauna-recovery-project/about/research</p>
                <br/>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Rewilding</div>
                <div className="badgePageItemInfo hidden">Investigate rewilding and reintroduction projects around the world. Consider pros and cons of returning
                apex predators to the wild, and examples of man-made problems caused by introducing non-native species to an area.</div>
                <div className="badgePageItemContent">
                    <p><strong>Rewilding and reintroduction projects around the world</strong></p>
                    <p>These are some examples of rewilding and reintroduction projects that have been undertaken around the world.</p>
                    <ol className="list">
                        <li><strong>Wolves in Yellowstone National Park, USA</strong>: In 1995, wolves were reintroduced to Yellowstone after being absent for decades. Their return dramatically reshaped the ecosystem, controlling elk populations, allowing vegetation to recover, and even altering river courses due to improved vegetation.</li>
                        <li><strong>Beavers in the UK and Scotland</strong>: Beavers, once extinct in many parts of the UK, have been reintroduced in areas like Dorset, Derbyshire, and Scotland. Their dams reduce flooding, create wetlands, and foster biodiversity.</li>
                        <li><strong>Wildebeests in the Serengeti, Africa</strong>: Efforts to manage diseases among wildebeests helped their population recover, restoring balance to the Serengeti ecosystem. This reduced wildfires and turned the area into a carbon sink.</li>
                        <li><strong>European Bison in Europe</strong>: Once nearly extinct, European bison have been reintroduced in countries like Poland and Romania. Their grazing maintains open landscapes, promoting plant diversity.</li>
                        <li><strong>Knepp Rewilding Project, England</strong>: This project transformed former farmland into a thriving wilderness by reintroducing native species and adopting a hands-off approach. It has become a model for nature restoration.</li>
                    </ol>
                    <br/>
                    <p>Looking more closely at the UK, here are a few more of the rewilding projects that have taken place here:</p>
                    <ol className="list">
                        <li><strong>White Stork Project:</strong> At Knepp Estate in Sussex, white storks have been reintroduced as part of a larger effort to bring back extinct species to British landscapes. This is one of the first reintroductions of these iconic birds in centuries.</li>
                        <li><strong>Pine Marten Recovery:</strong> Projects in Scotland and Wales are helping this elusive predator make a comeback. Their presence helps control grey squirrel populations, indirectly aiding native red squirrels.</li>
                        <li><strong>Wild Bison in Kent:</strong> In 2022, bison were introduced into a nature reserve as part of a project to restore natural grazing systems, which improve woodland biodiversity.</li>
                    </ol>
                    <br/><br/>
                    <p><strong>Pros and Cons of returning apex predators to the wild:</strong></p>
                    <p>It can be complex and controversial to return apex predators to the wild, here are some of the pros and cons:</p>
                    <br/>
                    <p><strong>Pros</strong></p>
                    <ol className="list">
                        <li><strong>Ecosystem Balance:</strong> Apex predators regulate prey populations, preventing overgrazing and promoting biodiversity. For example, the reintroduction of wolves to Yellowstone National Park restored vegetation and improved habitats for other species.</li>
                        <li><strong>Trophic Cascades:</strong> Predators influence entire ecosystems through trophic cascades. Their presence can lead to healthier ecosystems by indirectly benefiting plants, herbivores, and smaller predators.</li>
                        <li><strong>Cultural and Ethical Value:</strong> Restoring predators to their natural habitats can be seen as an ethical responsibility to repair human-caused damage. It also reconnects people with the natural world.</li>
                        <li><strong>Tourism and Economic Benefits:</strong> Wildlife tourism can thrive in areas where apex predators are reintroduced, boosting local economies.</li>
                        <li><strong>Climate Change Mitigation:</strong> By promoting vegetation growth and carbon sequestration, apex predators can contribute to combating climate change.</li>
                    </ol>
                    <br/>
                    <p><strong>Cons:</strong></p>
                    <ol className="list">
                        <li><strong>Human-Wildlife Conflict:</strong> Predators may attack livestock or come into conflict with humans, leading to economic losses and safety concerns.</li>
                        <li><strong>Habitat Limitations:</strong> Modern landscapes are often fragmented by human development, making it difficult for predators to thrive without encroaching on human areas.</li>
                        <li><strong>Impact on Prey Populations:</strong> Sudden changes in predator-prey dynamics can destabilize ecosystems, especially if prey populations are already vulnerable.</li>
                        <li><strong>Cost and Resources:</strong> Reintroduction programs require significant funding, monitoring, and management to ensure success.</li>
                        <li><strong>Public Opposition:</strong> Local communities may resist reintroduction efforts due to fears of predation or economic loss.</li>
                    </ol>
                    <br/><br/>
                    <p><strong>Man-made problems of re-introduction</strong></p>
                    <p>Introducing non-native species can lead to significant ecological, economic, and social problems. The examples below highlight the unintended consequences of introducing non-native species.</p>
                    <br/>
                    <ol className="list">
                        <li><strong>Cane Toads in Australia:</strong>
                            <ul>
                                <li>Problem: Introduced in 1935 to control agricultural pests, cane toads became invasive, preying on native species and poisoning predators with their toxic skin.</li>
                                <li>Impact: They disrupted ecosystems and caused declines in native wildlife populations.</li>
                            </ul>
                        </li>
                        <li><strong>Rabbits in Australia:</strong>
                            <ul>
                                <li>Problem: Brought by European settlers in the 18th century, rabbits multiplied rapidly due to a lack of natural predators.</li>
                                <li>Impact: They caused widespread soil erosion and destruction of native vegetation.</li>
                            </ul>
                        </li>
                        <li><strong>Zebra Mussels in North America:</strong>
                            <ul>
                                <li>Problem: Accidentally introduced via ballast water from ships, zebra mussels spread rapidly in freshwater systems.</li>
                                <li>Impact: They clog water intake pipes, outcompete native species, and alter aquatic ecosystems.</li>
                            </ul>
                        </li>
                        <li><strong>Kudzu Vine in the United States:</strong>
                            <ul>
                                <li>Problem: Introduced from Asia for erosion control, kudzu grows aggressively and smothers native plants.</li>
                                <li>Impact: It has become a major invasive species in the southeastern U.S., damaging ecosystems and infrastructure.</li>
                            </ul>
                        </li>
                        <li><strong>Grey Squirrels in the UK:</strong>
                            <ul>
                                <li>Problem: Introduced from North America in the 19th century, grey squirrels outcompete native red squirrels for resources.</li>
                                <li>Impact: They have contributed to the decline of red squirrel populations and damage to trees.</li>
                            </ul>
                        </li>
                        <li><strong>Lionfish in the Caribbean:</strong>
                            <ul>
                                <li>Problem: Likely introduced through the aquarium trade, lionfish have spread throughout the Caribbean and Atlantic.</li>
                                <li>Impact: They prey on native fish, disrupting coral reef ecosystems and threatening biodiversity.</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Sponsor</div>
                <div className="badgePageItemInfo hidden">Sponsor an animal - either in the wild or at a sanctuary - or donate animal care products to a 
                foodbank or sanctuary. Have a clear understanding of how your donation will be used.</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I have adopted eight lobster babies from The National Lobster Hatchery in Cornwall! I couldn't get a family of five, so I adopted two families of 4 and named them:</p>
                    <ul className="list">
                        <li>Rosa</li>
                        <li>Bader</li>
                        <li>Nelson</li>
                        <li>Mercury</li>
                        <li>Greta</li>
                        <li>Raina</li>
                        <li>Lester</li>
                        <li>Rebel</li>
                    </ul>
                    <br/>
                    <p>I have a certificate, an adoption pack and will be able to track them via the website once they are released into the wild!</p>
                    <br/>
                    <img src={Lobsters} alt="Screenshot of an order made with The National Lobster Hatchery" width="400" height="256" className="imgThird" />
                    <img src={Cert1} alt="Letter of Lobster Adoption" width="400" height="400" className="imgThird" />
                    <img src={Cert2} alt="Cetificates of Lobster Adoption" width="400" height="400" className="imgThird" />
                    <br/>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Diet</div>
                <div className="badgePageItemInfo hidden">Review your diet and purchasing habits. What changes can you make to help animals? Try one for at least three months</div>
                <div className="badgePageItemContent">
                    <p>I am always very conscious of my purchasing habits when it comes to being animal friendly. I haven't switched fully to a vegetarian or vegan diet, but I am much more flexible with my diet now. I purchase cruelty-free products and shop for products using sustainable materials from ethical companies where I can.</p>
                    <br/>
                    <p>These are some of the changes that can be made:</p>
                    <br/>
                    <p><strong>Diet habits</strong></p>
                    <ul className="list">
                        <li><strong>Plant-Based Choices:</strong> Reducing or eliminating animal products helps decrease demand for industrial farming, which often involves poor animal welfare and environmental harm. Even small changes, like "Meatless Mondays," make a difference.</li>
                        <li><strong>Sustainable Seafood:</strong> Choose seafood certified by organizations like the Marine Stewardship Council (MSC) to support responsible fishing practices and protect marine life.</li>
                        <li><strong>Organic and Free-Range Products:</strong> If consuming animal products, prioritize organic, free-range, or pasture-raised options that adhere to higher welfare standards.</li>
                        <li><strong>Avoid Exotic or Endangered Species:</strong> Be cautious about consuming or buying products derived from vulnerable species or unsustainable practices.</li>
                    </ul>
                    <br/>
                    <p><strong>Purchasing habits</strong></p>
                    <ul className="list">
                        <li><strong>Cruelty-Free Products:</strong> Buy cosmetics, toiletries, and cleaning supplies labeled as cruelty-free, meaning they haven't been tested on animals.</li>
                        <li><strong>Sustainable Materials:</strong> Avoid products made from animal skins, furs, or ivory. Opt for plant-based or synthetic alternatives.</li>
                        <li><strong>Ethical Companies:</strong> Support businesses committed to animal welfare and sustainability.</li>
                        <li><strong>Local and Seasonal:</strong> Choose local, seasonal produce to reduce carbon footprints and support small-scale farming over large industrial operations.</li>
                    </ul>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Organisations</div>
                <div className="badgePageItemInfo hidden">Learn about an organisation which works specifically with animals - either in your area or abroad. Fundraise for the organisation.</div>
                <div className="badgePageItemContent">
                    <p>I have been supporting Explorers Against Extinction for a while now, they are a UK-based conservation charity who work work with endangered animals worldwide.</p>
                    <br/>
                    <p>From their website:</p>
                    <p>"Explorers Against Extinction is a UK based conservation charity working with partners worldwide to recover key species and restore ecosystems. We focus on positive action to make a tangible difference, from funding reforestation projects, fieldwork, monitoring and scientific research, to those promoting human-wildlife co-existence."</p>
                    <br/>
                    <p>I help fundraise with them by providing artworks for their competitions, which are then auctioned or sold to raise money for their global projects. The artworks that I have submitted so far have had some success and raised over £100.</p>
                    <br/>
                </div>
            </div>   
        </div>
    )
}

export default AdvancedAnimalLover