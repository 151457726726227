import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import BookPlate from '../images/librarian-bookplate.png';
import Bookmark1 from '../images/librarian-bookmark2.jpg';
import Bookmark2 from '../images/librarian-bookmark3.jpg';
import Bookmark3 from '../images/librarian-bookmark4.jpg';
import Bookmark4 from '../images/librarian-bookmark5.jpg';
import Bookmark5 from '../images/librarian-bookmark6.jpg';
import Bookmark6 from '../images/librarian-bookmark7.jpg';
import Bookmark7 from '../images/librarian-bookmark8.jpg';
import Bookmark8 from '../images/librarian-bookmark9.jpg';
import Bookmark9 from '../images/librarian-bookmark10.jpg';
import Bookshelf1 from '../images/librarian-origami1.jpg';
import Bookshelf2 from '../images/librarian-origami2.jpg';
import Bookshelf3 from '../images/librarian-origami3.jpg';
import Bookshelf4 from '../images/librarian-origami4.jpg';
import Bookshelf5 from '../images/librarian-origami5.jpg';
import Bookshelf6 from '../images/librarian-origami6.jpg';
import Bookshelf7 from '../images/librarian-origami7.jpg';
import Bookshelf8 from '../images/librarian-origami8.jpg';
import Book1 from '../images/librarian-book1.jpg';
import Book2 from '../images/librarian-book2.jpg';
import Book3 from '../images/librarian-book3.jpg';
import Book4 from '../images/librarian-book4.jpg';
import Book5 from '../images/librarian-book5.jpg';
import Book6 from '../images/librarian-book6.jpg';
import Book7 from '../images/librarian-book7.jpg';
import Book8 from '../images/librarian-book8.jpg';
import Book9 from '../images/librarian-book9.jpg';
import Book11 from '../images/librarian-book11.jpg';
import Book12 from '../images/librarian-book12.jpg';
import Book13 from '../images/librarian-book13.jpg';
import Book14 from '../images/librarian-book14.jpg';
import Book15 from '../images/librarian-book15.jpg';
import Book16 from '../images/librarian-book16.jpg';
import Book17 from '../images/librarian-book17.jpg';
import Book18 from '../images/librarian-book18.jpg';
import Book19 from '../images/librarian-book19.jpg';


const Librarian = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Librarian"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemInfo olive-light">Cataloguing</div>
                <div className="badgePageItemInfo hidden">Learn about the different ways to catalogue books, including the dewey decimal system and genrefication</div>
                <div className="badgePageItemContent">
                    <p>During my research, I came across the following methods of cataloguing:</p>
                    <ul className="list">
                        <li>Dewey Decimal Classification (DDC) - categorises books into 10 main classes, representing different subject area. Used in public and school libraries.</li>
                        <li>Library of Congress Classification (LCC) - categorises books using letters and numbers to represent subject areas. Used by academic and research libraries.</li>
                        <li>Universal Decimal Classification (UDC) - extension of DDC, used internationally and allows more detailed classification using number and decimal points.</li>
                        <li>Subject Headings - uses controlled vocabularies like the Library of Congress Subject Headings (LCSH) to assign terms that describe the content of each book.</li>
                        <li>Online Public Access Catalogs (OPAC) - integrates various cataloguing methods for easy searching, used in modern libraries that provide digital libraries that can be searched by title, author, subject or keywords.</li>
                        <li>Genre or Special Collections - catalogues by genre, or used to create special collections based on themes or topics.</li>
                        <li>Barcode and RFID - used for inventory management and tracking. Each book has a unique identifier that can be scanned for quick check-out and return.</li>
                    </ul>
                    <br/><br/>
                    <p><strong>Genrefication</strong></p>
                    <p>This involves organising books by genre rather than by using traditional classification system. It groups books into broad categories or genres such as mystery, romance, science fiction etc.</p>
                    <p>The purpose is to make it easier for people to find books that they are interested in, locating genres without having to go through the whole library.</p>
                    <p>Benefits include:</p>
                    <ul className="list">
                        <li>Enhanced browsing - easily browse for genres</li>
                        <li>Increased circulation - books in more popular genres are more likely to be checked out when they are grouped together.</li>
                        <li>Improved accessibility - helps people who aren't familiar with standard classification systems.</li>
                        <li>Engagement - makes libraries feel less intimidating, especially for younger readers.</li>
                    </ul>
                    <br/>
                    <p>It can sometimes make it harder to find books that cover more than one genre. It is however considered a user-friendly system and is used by a lot of public and school libraries.</p>
                    <br/><br/>
                    <p><strong>The Dewey Decimal System</strong></p>
                    <p>Created by Melvil Dewey in 1876. Divides all knowledge into ten broad classes that are then subdivided. Each class has a three digit number:</p>
                    <ul className="list">
                        <li>000 - Generalities: Encyclopedias, newspapers, general reference works.</li>
                        <li>100 - Philosophy and Psychology: Metaphysics, ethics, logic, psychology.</li>
                        <li>200 - Religion: Comparative religion, the Bible, Christianity, other religions.</li>
                        <li>300 - Social Sciences: Sociology, economics, law, education, folklore.</li>
                        <li>400 - Language: Linguistics, specific languages, dictionaries, grammar.</li>
                        <li>500 - Natural Sciences and Mathematics: Mathematics, astronomy, physics, chemistry, biology.</li>
                        <li>600 - Technology (Applied Sciences): Medicine, engineering, agriculture, home economics.</li>
                        <li>700 - The Arts: Art, music, sports, photography, architecture.</li>
                        <li>800 - Literature: Poetry, drama, fiction, essays, literary criticism.</li>
                        <li>900 - History and Geography: History, geography, travel, biography.</li>
                    </ul>
                    <br/>
                    <p>Each class is then divided into ten divisions and then each division split into sections, for example:</p>
                    <ul className="list">
                        <li>500 - Natural Sciences and Mathematics
                            <ul className="list">
                                <li>510 - Mathematics
                                    <ul className="list">
                                        <li>511 - General principles of mathematics</li>
                                        <li>512 - Algebra</li>
                                        <li>513 - Arithmetic</li>
                                        <li>514 - Topology</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br/>
                    <p>Further breakdowns are done by adding decimal points, for example:</p>
                    <ul className="list">
                        <li>530 - Physics
                            <ul className="list">
                                <li>531 - Classical mechanics
                                    <ul className="list">
                                        <li>531.1 - Statics</li>
                                        <li>531.2 - Dynamics</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br/><br/>
                    <p>Each book is given a number based on its subject matter. Librarians use reference tools and the DDC manual to determine this number. The number is placed on the spine of the book and entered into the library's catalog.</p>
                    <br/>
                    <p>Some benefits are:</p>
                    <ul className="list">
                        <li>Uniformity - consistent across libraries</li>
                        <li>Accessibility - easier to locate resources</li>
                        <li>Adaptability - can be expanded for new subjects</li>
                    </ul>
                    <br/><br/>
                    <p>Some drawbacks are:</p>
                    <ul className="list">
                        <li>Complexity - can be intimidating to people not familiar with libraries</li>
                        <li>Western Bias - can sometimes marginalise non-Western subjects and perspectives</li>
                        <li>Inflexibility - can be slow to adapt to changes, new areas of knowledge or interdisciplinary subjects</li>
                        <li>Subjectivity - classification or categories can be subjective and the same book in different libraries could be classified differently</li>
                        <li>Lack of Browsability - harder to find books by interest or those that appear in more than one area</li>
                    </ul>
                    <br/><br/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2</div>
                <div className="badgePageItemInfo olive-light">Personal Library</div>
                <div className="badgePageItemInfo hidden">Reorganise and Catalogue your own book collection as a personal library</div>
                <div className="badgePageItemContent">
                    <p>Well this took a good few hours!! It was a good exercise though to clear out a few books and ... I found my Maverick Bronze book that I had been missing!!</p>
                    <p>I found that I have about 150 books in my permanent collection and I classified all of them using the Dewey Decimal System.</p>
                    <br/>
                    <p>The first resource I used was the Library of Congress catalog which gave me the majority of genres / subjects and classification codes.</p>
                    <p>https://catalog.loc.gov/index.html</p>
                    <br/>
                    <p>I then used the Open Library to track down a few more, this gave me some more codes.</p>
                    <p>https://openlibrary.org/</p>
                    <br/>
                    <p>For the codes that I found on Open Library, I then used Bing Co-Pilot to get the descriptions for those codes and also referrred to this list:</p>
                    <p>https://en.wikipedia.org/wiki/List_of_Dewey_Decimal_classes#</p>
                    <br/>
                    <p>I still had a few missing, so I then had a conversation with Co-Pilot to ask what would be the appropriate classification for the genres and the description of those codes.</p>
                    <br/>
                    <p>Using these combined methods I was able to classify my entire library, and that is now stored in a spreadsheet! I have re-organised my bookshelves where needed to put the correct classifications together, although most things were already correctly grouped together due to similar authors and genres.</p>
                    <br/>
                    <p>I categorised both fiction and non-fiction and found that I have got books that span all of the main DDC classes:</p>
                    <ul className='list'>
                        <li>000 - Computer science, information, and general works</li>
                        <li>100 - Philosophy and psychology</li>
                        <li>200 - Religion</li>
                        <li>300 - Social Sciences</li>
                        <li>400 - Language</li>
                        <li>500 - Science</li>
                        <li>600 - Technology</li>
                        <li>700 - Arts and Recreation</li>
                        <li>800 - Literature</li>
                        <li>900 - History and geography</li>
                    </ul>
                    <br/>
                    <p>This exercise satisfied my inner geek!</p>
                    <br/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3</div>
                <div className="badgePageItemInfo olive-light">Bookplate</div>
                <div className="badgePageItemInfo hidden">Design a bookplate for your home library</div>
                <div className="badgePageItemContent">
                    <p>Here's my fancy bookplate, designed in black and white so it can be coloured in as desired.</p>
                    <img src={BookPlate} alt="Illustrated bookplate with the words Ex Libris at the top and a large illustrated letter A and space for a name and year." className="imgThird" />
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4</div>
                <div className="badgePageItemInfo olive-light">Local Library</div>
                <div className="badgePageItemInfo hidden">Get to know your local library. What additional services does it provide? Dies it run events?
                How can you get involved or support it?</div>
                <div className="badgePageItemContent">
                    <p>I am lucky to have two libraries within walking distance and a larger one a little further out. I don't visit them often any more, but in years gone by I have been a very regular library user.</p>
                    <br/>
                    <p><strong>Christchurch Library</strong></p>
                    <p>This library is open Monday to Saturday every week. They offer the following resources:</p>
                    <ul className="list">
                        <li>Books, DVDs and audiobooks</li>
                        <li>E-Magazines through BorrowBox</li>
                        <li>Free internet access and public wifi</li>
                        <li>Warm spaces for studying, reading and meeting</li>
                        <li>Photocopying and printing</li>
                        <li>24/7 drop box for returns</li>
                        <li>Water cooler to refill bottles</li>
                        <li>Jigsaws</li>
                        <li>Rooms to hire</li>
                        <li>Toilets - including baby change and accessible</li>
                        <li>Hearing Loop</li>
                    </ul>
                    <br/>
                    <p>They also run regular weekly sessions for Adult and Children and Families</p>
                    <ul className="list">
                        <li>Tech Buddy</li>
                        <li>Knit and Natter</li>
                        <li>Board Games</li>
                        <li>Wriggle and Rhyme</li>
                        <li>Storytime Tribe</li>
                        <li>Lego Club</li>
                    </ul>
                    <br/>
                    <p>They also host regular talks by visiting speakers, seasonal events for school holidays, workshops and courses from different organisations. They support referrals to other services, the local Win on Waste sessions and are a drop-off point for the local food bank.</p>
                    <br/><br/>
                    <p><strong>Highcliffe Lbrary</strong></p>
                    <p>This library is open Monday, Tuesday, Thursday, Friday at Saturday at varying times. They offer the following resources:</p>
                    <ul className="list">
                        <li>Books and audiobooks</li>
                        <li>Free internet access and public wifi</li>
                        <li>Warm spaces for studying, reading and meeting</li>
                        <li>Photocopying and printing</li>
                        <li>daily newspapers</li>
                        <li>24/7 drop box for returns</li>
                        <li>Jigsaw swap</li>
                        <li>community jigsaw and scrabble game</li>
                        <li>The library is wheelchair accessible and has a hearing induction loop</li>
                    </ul>
                    <br/>
                    <p>They also run regular weekly sessions for Adult and Children and Families</p>
                    <ul className="list">
                        <li>Stay and Play (duplo)</li>
                        <li>Wriggle and Rhyme</li>
                        <li>Toddler Time</li>
                    </ul>
                    <br/><br/>
                    <p><strong>Bournemouth Library</strong></p>
                    <p>The larger Bournemouth library is a bus journey away and as well as the usual services you can also access local history documents and resources here as well as get access to the Music Library where you can borrow music books, sheet music, orchestral and choral sets and have access to an electric piano to play.</p>
                    <p>They also host regular talks by visiting speakers, seasonal events for school holidays, workshops and courses from different organisations.</p>
                    <br/><br/>
                    <p>Library information: https://www.bcpcouncil.gov.uk/libraries</p>
                    <br/><br/>
                    <p><strong>Volunteering</strong></p>
                    <p>Volunteering opportunities do come up at the libraries so that members of the community can support various activities, including:</p>
                    <ul className="list">
                        <li>Tech buddies - help with computer and IT skills, provide assistance with phones and laptops</li>
                        <li>Home Library Service - help to deliver books to those who can't get to the library</li>
                        <li>Organise and set up reading groups and children's activities</li>
                        <li>Teach children how to code at Coding Clubs</li>
                        <li>Promote the yearly Summer Reading Challenge</li>
                    </ul>     
                    <br/>
                    <p>Volunteering information: https://www.bcpcouncil.gov.uk/libraries/volunteer-at-our-libraries</p>          
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5</div>
                <div className="badgePageItemInfo olive-light">Read</div>
                <div className="badgePageItemInfo hidden">Read a book in a manner you've never tried before - audiobook, kindle, interlinear </div>
                <div className="badgePageItemContent">
                    <p>For this clause I have purchased a kindle version of the book "Learn Spanish with Starter Stories" which is in interlinear format.</p>
                    <p>It's a collection of 18 short stores and I really enjoyed reading in this format, so will be buying more in other languages too.</p>
                    <br/>
                    <div className="gallery">
                        <div className="galleryItem"><img src={Book1} alt="Screenshot of an Interlinear book titled Learn Spanish with Starter Stories" className="imgThird" /></div>
                        <div className="galleryItem"><img src={Book2} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book3} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book4} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book5} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book6} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book7} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book8} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book9} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book11} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book12} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book13} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book14} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book15} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book16} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book17} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book18} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                        <div className="galleryItem"><img src={Book19} alt="Screenshot of a page from the interlinear book Learn Spanish with Starter Stories" /></div>
                    </div>
                    
                    <br/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 6</div>
                <div className="badgePageItemInfo olive-light">Banned</div>
                <div className="badgePageItemInfo hidden">Learn about 10 banned books - why were they banned and where?</div>
                <div className="badgePageItemContent">
                    <p>As I researched this, it became challenging to find books that had been banned anywhere other than the United States! So I have looked at more than 10 to try and find those that were banned more widely. These books were banned in the US during 2024:</p>
                    <ul className="list">
                    <li>Nineteen Minutes, Jodi Picoult - Although based on the topic of school shootings, the book was ultimately banned over one single page that mentioned a sexual assualt. Banned in more than 50 school dstricts in the US.</li>
                    <li>Looking for Alaska, John Green - Banned for its themes of gender ideology and alternative sexualities, although it is reported that it has no queer characters or references to LGBTQ+ individuals. Banned in 5 US states.</li>
                    <li>The Handmaid's Tale, Margaret Attwood - Banned for obscene and graphic content in relation to sexuality, profanity and themes considered inappropriate for some age groups or offensive to religious values. It has primarily been banned in various US States, but is also banned in Iran due to the themse of gender oppression and religious extremism. </li>
                    <li>The Perks of Being a Wallflower, Stephen Chbosky - Banned for portrayal of homosexuality, depiction of drug and alcohol use, sexual acts and sexual assault. Banned in various US States.</li>
                    <li>Thirteen Reasons Why, Jay Asher - Banned for glamorising teen suicide and embelling mental illness. Banned in Canada and various US States. </li>
                    <li>The Kite Runner, Khaled Hosseini - Banned for violence, specifically against children and child sexual abuse. Banned in various US States.</li>
                    <li>The Bluest Eye, Toni Morrison - banned for sexually explicit and EDI content. Banned in various US states.</li>
                    <li>The Color Purple, Alice Walker - Banned for profanity, portryal of black men, display of LGBTQ themes and sexual violence. Banned in various US States.</li>
                    <li>Sold, Patricia McCormack - banned for explicit sexual content and discussions of rape. Banned in various US States.</li>
                    <li>Crank, Ellen Hopkins - banned for depiction of drug abuse, inappropriate language and sexual content. Banned in various US States.</li>
                    <li>Identical, Ellen Hopkins - banned for reasons including drug use, alcohol abuse, sexual abuse and violence. Banned in various US States.</li>
                    <li>Tricks, Ellen Hopkins - banned for inclusion of sexually explicit content and sexual assault as well as inclusion of LGBTQ+ characters. Banned in various US States.</li>
                    <li>A Court of Thorns and Roses, Sarah J. Maas - banned for violence and sexual content. Banned in various US States.</li>
                    <li>Empire of Storms, Sarah J. Maas - banned based on sexual content. </li>
                    <li>The Absolutely True Diary of a part-time Indian, Serman Alexie - banned for vulgar language and sexual content, also viewed as being Anti-Christian. Banned in various US States.</li>
                    <li>Water for Elephants, Sara Gruen - banned for sexually explicit content. Banned in various US States.</li>
                    </ul>
                    <p>Source: https://bookstr.com/article/here-is-every-official-banned-and-challenged-book-of-2024/</p>
                    <br/><br/>
                    <p>Some books banned in the UK and elsewhere:</p>
                    <ul className="list">
                        <li>The Absolutely True Diary of a part-time Indian, Serman Alexie - whilst not banned, this book has been removed from UK curriculums and libraries due to explicit content and language.</li>
                        <li>The Hate U Give, Angie Thomas - removed from curriculums and libraries for themes of police brutality and racial injustice.</li>
                        <li>The Catcher in the Rye, J. D. Salinger - challenged in some schools due to themes of teenage rebellion and profanity.</li>
                    </ul>
                    <br/>
                    <p>There haven't been many book bans in the UK, and those previously banned are no longer banned.</p>
                    <ul className="list">
                        <li>Lady Chatterly's Lover, D. H. Lawarence - Banned for obscenity until the 1960s</li>
                        <li>Ulysses, James Joyce - Banned for obscenity until the 1930s</li>
                    </ul>
                    <br/>
                    <p>These books have been banned or removed from curriculums and libraries in several countries:</p>
                    <ul className="list">
                        <li>The Satanic Verses, Salman Rushdie - banned in several Muslim-majority countries including Iran, Saudi Arabia and Egypt due to perceived blasphemy against Islam.</li>
                        <li>Lolita, Vladimir Nabokov - banned in Argentina and the US due to its theme of a middle-aged man's obsession with a young girl.</li>
                        <li>1984 by George Orwell - banned in various countries at different times for themes of totalitarianism and government surveillance.</li>
                        <li>The Catcher in the Rye, J. D. Salinger - banned in multiple countries for themes of teenage rebellion and profanity.</li>
                        <li>To Kill a Mockingbird, Harper Lee - banned in several places for its themes of racial injustice and use of offensive language.</li>
                    </ul>
                    <br/>
                    <p>There are many other examples, here are a few more well-known ones:</p>
                    <ul className="list">
                        <li>The Diary of a Young Girl, Anne Frank - banned in Lebanon for portraying Jews in a favourable light.</li>
                        <li>The Adventures of Huckleberry Fin, Mark Twain - banned for themes of racial slurs and racism.</li>
                        <li>Brave New World, Aldous Huxley - banned for use of drugs and sexual content.</li>
                        <li>Fifty Shades of Grey, E. L. James - banned for sexually explicit content.</li>
                    </ul>
                    <br/>
                    <p>Sourced from conversations with Bing Co-Pilot and the following web sites:</p>
                    <p>https://www.pbs.org/wnet/americanmasters/blog/here-are-the-100-most-banned-and-challenged-books-of-the-decade/</p>
                    <p>https://www.banned-books.org.uk/all</p>
                    <br/><br/>
                    <p>Although this clause doesn't ask for this information, I came across this site whilst researching and it has some very interesting statistics:</p>
                    <p>https://wordsrated.com/global-book-banning-statistics/</p>
                    <p>The following information has been taken directly from that site and although most of the books I looked at were banned in the US, they aren't the country who ban the most books!</p>
                    <br/>
                    <ul className="list">
                        <li>From the available records, India is the country that historically accounts for most of the book bans in the World, as 11.11% of all recorded bans occurred in India at some point.</li>
                        <li>China is responsible for 8.99% of all book bans, and Singapore accounts for 8.47%.</li>
                        <li>Ireland (6.35% of all bans) has the most available recorded bans outside of Asia.</li>
                        <li>Australia and US (5.29%) close the group of the top 5 countries.</li>
                        <li>Germany, Spain, Russia, and the UK are the remaining members of the top 10 group.</li>
                    </ul> 
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 7</div>
                <div className="badgePageItemInfo olive-light">Record</div>
                <div className="badgePageItemInfo hidden">Find a creative way to record all the books you read in a year</div>
                <div className="badgePageItemContent">
                <p>Many years ago I made a mini bookshelf in a carpentry class at school. I loved it so much and was so proud of it I kept it all these years! It's had a few different purposes but this year I am using it to track my reading for this clause.</p>
                <p>For every book that I read this year, I am going to make a little origami book to put on the shelves. I'll make the cover colour the main colour of the book dust jacket.</p>
                <p>I followed the instructions in this tutorial to make the first book, although I worked to my own dimensions to make a book that is 3cm wide.</p>
                <p>YouTube tutorial: https://www.youtube.com/watch?v=FXwHlv1sq0s</p>
                <br/>
                <p>Here's my first book in progress and on the shelves:</p>
                <div className="gallery">
                    <div className="galleryItem"><img src={Bookshelf1} alt="Paper folded to make mini book pages" /></div>
                    <div className="galleryItem"><img src={Bookshelf2} alt="Paper folded to make mini book pages" /></div>
                    <div className="galleryItem"><img src={Bookshelf3} alt="Paper folded to make mini book pages" /></div>
                    <div className="galleryItem"><img src={Bookshelf4} alt="Paper folded and glued into mini book pages" /></div>
                    <div className="galleryItem"><img src={Bookshelf5} alt="Yellow paper folded to make a mini book cover" /></div>
                    <div className="galleryItem"><img src={Bookshelf6} alt="A mini origami folded book with a yellow cover" /></div>
                    <div className="galleryItem"><img src={Bookshelf7} alt="A completed mini origami book" /></div>
                    <div className="galleryItem"><img src={Bookshelf8} alt="A mini origami book on a miniature wooden book shelf" /></div>
                </div>
                <br/>
                <p>I'll add photos as I add more books.</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 8</div>
                <div className="badgePageItemInfo olive-light">Make</div>
                <div className="badgePageItemInfo hidden">Make a book nook, book ends, a fancy bookmark, or a fake book storage box</div>
                <div className="badgePageItemContent">
                    <p>I chose to make a fancy bookmark for this clause. I made it using Pergamano techniques and mounted it on cream coloured card.</p>
                    <br/>
                    <div className="gallery">
                        <div className="galleryItem"><img src={Bookmark1} alt="Pattern, tools and ink bottles ready for a Pergamano project" /></div>
                        <div className="galleryItem"><img src={Bookmark2} alt="A Pergamano bookmark in progress, showing outlining done in ink" /></div>
                        <div className="galleryItem"><img src={Bookmark3} alt="A Pergamano bookmark in progress, showing embossing and pricking work" /></div>
                        <div className="galleryItem"><img src={Bookmark4} alt="A Pergamano bookmark in progress, showing cut out crosses" /></div>
                        <div className="galleryItem"><img src={Bookmark5} alt="A Pergamano bookmark in progress, laid on a rainbow parchment paper" /></div>
                        <div className="galleryItem"><img src={Bookmark6} alt="A Pergamano bookmark in progress, mounted on a rainbow parchment paper" /></div>
                        <div className="galleryItem"><img src={Bookmark7} alt="A Pergamano bookmark in progress, laid on a cream card backing" /></div>
                        <div className="galleryItem"><img src={Bookmark8} alt="A Pergamano bookmark in progress, mounted on a cream card backing" /></div>
                        <div className="galleryItem"><img src={Bookmark9} alt="A completed Pergamano bookmark, mounted on rainbow parchment and a cream card backing" /></div>
                    </div>
                    <br/>
                </div>
            </div>   
        </div>
    )
}

export default Librarian