import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Audio from '../videos/TheRiver-Audio.mp4';
import Sight from '../images/Musician-SightReading.jpg';
import Learn from '../images/Musician-Learn.jpg';

const Musician = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Musician"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Practise</div>
                    <div className="badgePageItemInfo reverse hidden">Take time out of your week to practise an instrument for at least 3 months.</div>
                    <div className="badgePageItemEvidence">
                        <p>I have been a pianist for many years, but I don't play much any more, so going back to regular practice has been nice.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Learn</div>
                    <div className="badgePageItemInfo reverse hidden">Find a song or piece of music which you cannot currently play and set yourself the challenge of learning to play it.</div>
                    <div className="badgePageItemEvidence">
                        <p>I bought this song book a very long time ago and never actually played any of the pieces, so I started with this one.</p>
                        <p>Always Remember Us This Way - </p>
                        <img className="imgThird" src={Learn} alt="Sheet music on top of a piano" widh="400" height="400" />
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Sight reading</div>
                    <div className="badgePageItemInfo reverse hidden">Practise sight reading and play a new piece of music by sight reading.</div>
                    <div className="badgePageItemEvidence">
                        <p>For some easy sight-reading pracice, I downloaded the sheet music for the song "Lost and Found" by Molly Sanden and Viktor Leksell.</p>
                        <img src={Sight} alt="Sheet music" className="imgThird"/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Performance</div>
                    <div className="badgePageItemInfo reverse hidden">Prepare a 15 minute musical programme to perform to friends or family.</div>
                    <div className="badgePageItemEvidence">
                        <p>This clause is backdated as I've done a lot of perfoming across the years and no longer play publicly.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Composition</div>
                    <div className="badgePageItemInfo reverse hidden">Create and perform a 16 bar composition.</div>
                    <div className="badgePageItemEvidence">
                        <p>Here's a video with the audio of my composition. I play flute as well as piano, but can't physically play them at the same time! So, I set up the parts in a score editor - Crescendo - and exported the score as a midi file. I then imported it into LMMS to turn it into a live-sounding performance with appropriate instrument packs.</p>
                        <p>The video thumbnail is a picture of me at my piano. It's a bit of a mysterious photo as I have no idea how it was taken! I was alone, with the video running at the time, no timer and you can see both of my hands in the shot, so how it appeared as a still is unknown!</p>
                        <video width="400" height="240" controls>
                            <source src={Audio} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Musician