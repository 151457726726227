import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Animals1 from '../images/animallover1.jpg';
import Animals2 from '../images/animallover2.jpg';
import Animals3 from '../images/animallover3.jpg';
import Animals4 from '../images/animallover4.jpg';
import Animals5 from '../images/animallover5.jpg';
import Animals6 from '../images/animallover6.jpg';
import Zoo1 from '../images/animallover-zoo1.png';
import Zoo2 from '../images/animallover-zoo2.png';
import Cert from '../images/animallover-firstaidcert.png';
import Book from '../documents/AnimalLover-TigerBook.pdf';
import Giraffe from '../images/bronze-myskills100.jpg';
import Tiger from '../images/animallover-tiger.jpg';
import Vaquita from '../images/animallover-vaquita.jpg';


const AnimalLover = () => {
    return (
        <div>
            <ActionBar area={"bookone"} />
            <EvidenceHeader title={"Animal Lover"} area={"bookone"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Extinction</div>
                <div className="badgePageItemInfo hidden">Learn about three animals that are close to extinction. What can we do to protect them?</div>
                <div className="badgePageItemContent">
                    <p>For this clause, I am looking at:</p>
                    <ul className="list">
                        <li>Giraffes</li>
                        <li>Tigers</li>
                        <li>Vaquitas</li>
                    </ul>
                    <br/>
                    <p>I have previously been involved in differrent initiatives for organisations that support each, so it felt appropriate!</p>
                    <br/>
                    <p><strong>Giraffes</strong></p>
                    <p>Over the last 3 decades, the giraffe population has declined by 40%. On the IUCN Red List it is currently assessed as "Vulnerable" with fewer than 70,000 mature individuals remaining in the wild and the population decreasing.</p>
                    <p>Giraffes on the IUCN Red List: https://www.iucnredlist.org/species/9194/136266699</p>
                    <p>Contributing factors are:</p>
                    <ul className="list">
                        <li>Habitat Loss and Fragmentation - caused by human activities such as agriculture, timber harvesting and infrastructure development. These activities limit the access to food and water, isolating populations.</li>
                        <li>Poaching - weak law enforcement means that poaching is still a big threat. Giraffes are poached for meat, hides and other body parts.</li>
                        <li>Climate Change - affects availability of food and water.</li>
                        <li>Human-Wildlife Conflict - Giraffes often come into conflict with farmers over land and resources as the human population expands.</li>
                    </ul>
                    <br/>
                    <p>There are conservation efforts underway to protect Giraffes through organisations like the Giraffe Conservation Foundation (GCF), these are some actions that are being taken:</p>
                    <ul className="list">
                        <li>Habitat Protection and Restoration - protection of habitats and restoration of areas including creating wildlife corridors to re-connect fragmented habitats.</li>
                        <li>Anti-Poaching Measures - increased patrols and strengthened law enforcement. Local community involvement in protecting Giraffes and their habitats through community-based natural resource management.</li>
                        <li>Research and Monitoring - Research into behaviour, genetics and habitat needs helps to inform conservation strategies and policies.</li>
                        <li>Awareness and Education - Raising global awareness and promoting conservation efforts. Educational programs in local communities.</li>
                    </ul>
                    <br/>
                    <p>I did a project for my Bronze Maverick to produce a colour pencil drawing of a Giraffe for the Sketch for Survival competition run by Explorers against Extinction. This is a fundraising initiative and all entries must involve an endangered species or habitat. The winning entries go into an online auction and the funds from that go to support frontline conservation projects around the world. This is the second time I have entered this competition.</p>
                    <br/>
                    <p>Here's my Giraffe, it made it through to the online gallery and was actually bought by another rebel!</p>
                    <img src={Giraffe} alt="Coloured pencil drawing of a giraffe" className="imgThird" />
                    <br/><br/>
                    <p><strong>Tigers</strong></p>
                    <p>The Tiger population has decreased to around only 3,900 in the wild. The IUCN Red List shows them as "Endangered" and the population is decreasing.</p>
                    <p>Tigers on the IUCN Red List: https://www.iucnredlist.org/species/15955/214862019</p>
                    <p>Contributing factors are:</p>
                    <ul className="list">
                        <li>Habitat loss and fragmentation - rapid deforestation, urbanisation and agricultural expansion. Interefers with the ability of Tigers to find prey and mate.</li>
                        <li>Poaching and Illegal Wildlife Trade - Poached for bones, skin and other body parts, highly valued in traditional medicine and as status symbols. One of the biggest threat to survival.</li>
                        <li>Human-Wildlife Conflict - As human populations expand, Tigers may attack livestock which leads farmers to kill them to protect their own animals.</li>
                        <li>Prey Depletion - overhunting of deer and boar reduces the food available to Tigers.</li>
                    </ul>
                    <p>Conservation and protection measures are in place through the World Wildlife Fund and Global Tiger Forum.</p>
                    <ul className="list">
                        <li>Habitat Restoration and Protection - expanding protected areas like national parks and wildlife reserves, creating wildlife corridors to reconnect fragmented habitats.</li>
                        <li>Anti-Poaching Efforts - Increased patrols, strengthened law enforcement, using technology such as camera traps and drones, stricter penalties.</li>
                        <li>Community Engagement and Education - Education and awareness for local communities, promoting sustainable livelihoods that don't rely on poaching or destruction of habitats, compensation for livestock loss to reduce conflict.</li>
                        <li>Prey Conservation - Regulation of hunting, restoring habitats and reducing human impact on prey species.</li>
                    </ul>
                    <br/>
                    <p>The first time that I entered the Sketch for Survival competition (see details above), I did a graphite drawing of a Tiger. This made it to the conservation collection and was on sale in the online gallery, but I don't know what happened to it. </p>
                    <img src={Tiger} alt="Portrait of a Tiger drawn with graphite pencils" className="imgThird"/>
                    <br/><br/>
                    <p><strong>Vaquitas</strong></p>
                    <p>Vaquitas are a species of porpoise and are the world's most endangered marine mammal. The IUCN Red List shows them as "Critically Endangered" with only 10 individuals remaining and population on the decline.</p>
                    <p>Vaquitas on the IUCN Red List: https://www.iucnredlist.org/species/17028/214541137</p>
                    <br/>
                    <p>Contributing factors are:</p>
                    <ul className="list">
                        <li>Habitat Degradation - Pollution and habitat degradation in the northern Gulf of California</li>
                        <li>Illegal Fishing Practices - often caught as bycatch in gillnets used for illegal fishing of another endangered species, the totoaba fish. The swim bladders of totoaba are highly valued in traditional Chinese medicine which leads to illegal fishing in the habitat where the Vaquitas live.</li>
                    </ul>
                    <br/>
                    <p>Organisations like the Sea Shepherd Conservation Society and the World Wildlife Fund are working to protect vaquitas, but the situation is dire.</p>
                    <ul className="list">
                        <li>Enforcing Fishing Regulations - Implementing and strictly enforcing bans on gillnets, providing vaquita safe fishing gear to local fishers to reduce bycatch.</li>
                        <li>Strengthening Law Enforcement - Increased patrols and surveillance, enhancing international collaboration to stop the illegal trade of totaba swim bladders.</li>
                        <li>Habitat Restoration - reducing pollution, restoring natural habitats.</li>
                        <li>Awareness and Education - Raising global awareness and the importance of conservation. Engaging local communities and provoding education on sustainable fishing practices.</li>
                    </ul>
                    <br/>
                    <p>I actually became aware of the plight of the Vaquita through the BarryM Instagram Channel, which shows the power of different marketing streams! They ran a competition in association with Ocean Generation for an eyeshadow palette, raising awareness of the Vaquitas and I was lucky enough to win it.</p>
                    <p>Here's the beautiful palette I received:</p>
                    <img src={Vaquita} alt="Makeup palette produced by BarryM to support the Vaquita" className="imgThird" />
                    <br/>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Visit</div>
                <div className="badgePageItemInfo hidden">Visit a zoo, farm or animal sanctuary</div>
                <div className="badgePageItemContent">
                <p>In May 2024 I visited Manor Wildlife Park in Pembrokeshire whilst on holiday with the family. Here are a few photos that I took:</p>
                <div className="gallery">
                    <div className="galleryItem"><img src={Animals1} alt="Ring tailed lemurs playing at the zoo" /></div>
                    <div className="galleryItem"><img src={Animals2} alt="Tiger laying in the grass at the zook" /></div>
                    <div className="galleryItem"><img src={Animals3} alt="Rhinoceros in a field at the zoo" /></div>
                    <div className="galleryItem"><img src={Animals4} alt="A camel in an enclosure at the zoo" /></div>
                    <div className="galleryItem"><img src={Animals5} alt="A Red Panda sitting in a tree in a zoo enclosure" /></div>
                    <div className="galleryItem"><img src={Animals6} alt="A Wallaby carrying a baby" /></div>
                </div>
                <p>https://www.manorwildlifepark.co.uk/</p>
                <br/>
                <p>I have also spent a couple of hours taking virtual Zoo tours so that I have something logged for this clause in 2025 too!</p>
                <p>Dublin Zoo have some lovely walkthroughs of their site and Dartmoor Zoo has a great interactive tour with a map that you can explore. </p>
                <br/>
                <img src={Zoo1} alt="Screenshot from the Dublin Zoo Virtual Tour website" class/>
                <br/>
                <p>https://www.dublinzoo.ie/virtual-tours-2/</p>
                <br/>
                <img src={Zoo2} alt="Screenshot from the Dartmoor Zoo Virtual Tour website" class/>
                <br/>
                <p>https://bestdevonholidays.co.uk/dartmoor_zoo/virtualtour.html</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Book</div>
                <div className="badgePageItemInfo hidden">Choose an animal. Make a book with facts and pictures for a child.</div>
                <div className="badgePageItemContent">
                    <p>Book linked below - not to be printed or distributed. This is a personal project, images have been sourced from the internet and are not licensed for use.</p>
                    <br/>
                    <p><a href={Book} className="link">The Little Book of Tigers</a></p>
                    <br/>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Animal Friendly</div>
                <div className="badgePageItemInfo hidden">Take an initiative that improves conditions for wildlife, e.g. bee-friendly plants</div>
                <div className="badgePageItemContent">
                    <p>This year I will be working on my Gardener and Carpenter badges, so I will be adding nature-friendly items to my garden. I already have a lavender bush that the bees love and put out food for the birds in coconut shells salvaged from making ice cream for a Rebel challenge!</p>
                    <p>In January 2025 I completed my Earth Runs 365 days active and planted over 300 trees.</p>
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">First Aid</div>
                <div className="badgePageItemInfo hidden">Complete a pet first aid course</div>
                <div className="badgePageItemContent">
                    <p>I completed an L2 VTQ course in Pet First Aid on 1st January 2025.</p>
                    <img src={Cert} alt="Certificate" width="500"/>
                </div>
            </div> 
        </div>
    )
}

export default AnimalLover