import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import medal from '../images/CupFeb25.jpg';

const CupFeb25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup January 2025 Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                   <p>For this challenge, I completed a 50 mile Walk in the Stars challenge through MedalMad. Evidence and medal were posted in the Facebook group as required.</p>
                    <img src={medal} alt="Collage showing challenge order and resulting medal" className="imgThird"/>
                </div>
            </div>

            
        </div>
    )
}

export default CupFeb25